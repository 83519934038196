import MarkerProps from "./MarkerProps";

export function PredictionMarker(props: MarkerProps) {
    const {
        x = 0,
        y = 0,
        color = 'white',
        width = 26,
        height = 50,
    } = props;
    return <g>
        <line x1={x} y1={y - height/2} x2={x} y2={y + height/2} stroke={color} stroke-dasharray="2 2"/>
        <line x1={x-width/2} y1={y - height/2} x2={x+width/2} y2={y - height/2} stroke={color} stroke-width="2"/>
        <line x1={x-width/2} y1={y + height/2} x2={x+width/2} y2={y + height/2} stroke={color} stroke-width="2"/>
        <circle cx={x} cy={y} r="5.5" fill={color}/>
    </g>;
}