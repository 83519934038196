import {
    Box,
    BoxProps,
    styled,
  } from "@mui/material";
import TypingEffect from "./TypingEffect";

const MainPage = styled(Box)<BoxProps>(({ theme }) => ({
    width: "100%",
    display: "none",
    padding: "20px",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
        display: "flex",
    },
}));

const ChatExamples = () => {
    return (
        <MainPage>
            <Box>
                <TypingEffect
                    messages={[
                        "How would a top scorer from another league adapt to our domestic competition?",
                        "What kind of player would best counterbalance our current squad’s weaknesses?",
                        "Who should we prioritize signing to improve our attacking depth?",
                        "How does this midfielder’s passing accuracy compare to others in their position across top leagues?",
                        "Who is the most underrated midfielder that we should consider signing?",
                        "What defender would be most effective against pacey wingers?",
                        "Who is the best option to strengthen our set-piece defense?",
                        "Who is the standout young talent we should recruit from a different league?",
                        "How does this forward’s style of play compare to our current team setup?",
                        "Who is the best playmaker available on the market?",
                        "What formation would best utilize this winger’s strengths in our squad?",
                        "Who is the top young prospect available in our region that we should sign?",
                        "What kind of player should we target to match up against teams with a physical playing style?",
                        "Who has been the most consistent performer in the transfer market over recent seasons?",
                        "What kind of players should we target to improve our away match performances?",
                        "Who is the most promising young striker we should sign from the lower divisions?",
                        "What type of players would help us adapt if promoted to a higher division?",
                        "Who is the best aerial threat available for recruitment in our league?",
                        "What attributes should we prioritize in a defensive midfielder to strengthen our team?",
                        "Who is the best candidate for a leadership role that we can bring into the squad?",
                        "Which versatile player would best fit our squad’s needs?",
                        "How does this striker’s shot conversion rate compare to others in the transfer market?",
                        "Who is the best dribbler available for recruitment right now?",
                        "Who is the most creative midfielder we could recruit to improve our midfield?",
                        "Which player has the potential to make an immediate impact as a new signing?",
                        "Who is the best defender available for improving our team’s defensive capabilities?",
                        "Which player would give us the best value for money in the transfer market?",
                        "What new signing could enhance our team’s performance in the upcoming season?",
                    ].sort(() => Math.random() - 0.5)}
                />
            </Box>
        </MainPage>
    )
}

export default ChatExamples