import React, { } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../store";
import {
    Box,
    TypographyProps,
    GridProps,
    Slide,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    Typography,
    styled,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { toggleAppModalMenu } from "../../store/viewReducer";
import BarChartIcon from '@mui/icons-material/BarChart';
import StarIcon from '@mui/icons-material/Star';
import SettingsIcon from '@mui/icons-material/Settings';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const GrigItem = styled(Grid)<GridProps>(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    gap: '8px',
    padding: '6px',
    width: '80px',
    alignItems: 'center',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
    },
}));

const StyledTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
    },
}));

export default function AppModalMenu() {
  const dispatch = useDispatch();
  const opened = useSelector((state: RootState) => state.view.appModalMenu.opened);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
        open={opened}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => dispatch(toggleAppModalMenu())}
        PaperProps={{
            sx: {
                position: 'fixed',
                top: '48px',
                right: isMobile ? 'unset' : '24px',
                margin: 'unset',
            }
        }}
        hideBackdrop
        transitionDuration={800}
    >
        <DialogContent >
            <Box>
            <Grid container spacing={4}>
                <GrigItem item xs={6}>
                    <IconButton color="inherit">
                        <BarChartIcon color="action" />
                    </IconButton>
                    <StyledTypography variant="body2">Player performance</StyledTypography>
                </GrigItem>
                <GrigItem item xs={6}>
                    <IconButton color="inherit">
                        <StarIcon color="action" />
                    </IconButton>
                    <StyledTypography variant="body2">Match performance</StyledTypography>
                </GrigItem>
                <GrigItem item xs={6}>
                    <IconButton color="inherit">
                        <StarIcon color="action" />
                    </IconButton>
                    <StyledTypography variant="body2">Season performance</StyledTypography>
                </GrigItem>
                <GrigItem item xs={6}>
                    <IconButton color="inherit">
                        <SettingsIcon color="action" />
                    </IconButton>
                    <StyledTypography variant="body2">Settings</StyledTypography>
                </GrigItem>
            </Grid>
            </Box>
        </DialogContent>
    </Dialog>
  );
}
