import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { ListItemButton, IconButton, Menu, MenuItem, ListItemIcon, TextField } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { selectConversation } from '../../store/conversationsReducer';
import { ConversationModel } from '../../models';

interface ConversationItemProps {
  conversation: ConversationModel;
  selected: boolean;
  to: string;
  onUpdateConversationName: (id: number, newName: string) => void;
  onDelete: (id: number) => void;
}

const StyledListItemButton = styled(forwardRef<HTMLDivElement, any>((props, ref) => <ListItemButton {...props} ref={ref} />))`
    height: 38px;
    display: flex;
    align-items: center;
    position: relative;
    &.MuiListItemButton-root.Mui-focusVisible {
      background-color: rgba(0, 152, 64, 0.08);
    }
    &:hover .icon-button {
        visibility: visible;
    }
`

const StyledTextField = styled(TextField)<{ selected: boolean }>`
    width: 85%;
    & .MuiInputBase-root.MuiInput-root.MuiInput-underline::before {
        border: 0px;
    }
    & .MuiInput-root::after {
        border: 0px;
    }    
    & .MuiInputBase-input {
        font-weight: ${props => (props.selected ? 600 : 200)};
        padding-left: 12px;
        font-size: 14px;
        cursor: pointer;
        border: 0px;
    }

`

const StyledMenuItem = styled(MenuItem)`
    font-size: 14px;
    font-weight: 200;
`

const StyledIconButton = styled(IconButton)`
    margin-left: auto;
    visibility: hidden;
    position: absolute;
    right: 8px;
`

const ConversationItem: React.FC<ConversationItemProps> = ({ conversation, selected, to, onUpdateConversationName, onDelete }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isEditable, setIsEditable] = useState(false);
  const [textValue, setTextValue] = useState(conversation.name);
  const [previousTextValue, setPreviousTextValue] = useState(conversation.name);
  const menuOpen = Boolean(menuAnchorEl);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditable && textFieldRef.current) {
      textFieldRef.current.focus();
      textFieldRef.current.selectionStart = textFieldRef.current.value.length;
    } else {
      textFieldRef.current?.blur();
    }
  }, [isEditable]);

  useEffect(() => {
    setTextValue(conversation.name);
  }, [conversation.name]);
  
  useEffect(() => {
    if (selected) {
      dispatch(selectConversation(conversation));
    }
  }, [selected, dispatch, conversation]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
    event.stopPropagation();
    event.preventDefault();
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(null);
    event.stopPropagation();
    event.preventDefault();
  };

  const handleUpdateConversationNameClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setPreviousTextValue(textValue);
    setIsEditable(true);
    setMenuAnchorEl(null);
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    onDelete(conversation.id);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextValue(event.target.value);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (isEditable === true) {
      setTextValue(previousTextValue);
      setIsEditable(false);
    }    
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setIsEditable(false);
      onUpdateConversationName(conversation.id, textValue);
      setPreviousTextValue(textValue); // after renaming, we have a new previous value (prevents onBlur from changing back)
    }
    if (event.key === 'Escape') {
      setIsEditable(false);
      setTextValue(previousTextValue)
    }
  };
  
  return (
    <StyledListItemButton component={RouterLink} selected={selected} to={to}>
      <StyledTextField
        selected={selected}
        value={textValue}
        onChange={handleTextChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        inputRef={textFieldRef}
        InputProps={{
          readOnly: !isEditable,
        }}
        variant="standard"
      />
      <StyledIconButton size="small" onClick={handleMenuOpen} className="icon-button">
        <MoreHorizIcon />
      </StyledIconButton>
      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledMenuItem onClick={handleUpdateConversationNameClick}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Rename
        </StyledMenuItem>
        <StyledMenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Delete
        </StyledMenuItem>
      </Menu>
    </StyledListItemButton>
  );
};

export default ConversationItem;