import { useDispatch } from 'react-redux';
// import { toggleSidebar, toggleAppModalMenu } from "../../store/viewReducer";
import { toggleSidebar } from "../../store/viewReducer";
import AppBar, { AppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as MenuIcon } from "./menu.svg";
// import EditIcon from '@mui/icons-material/Edit';
// import AppsIcon from '@mui/icons-material/Apps';
import { Box, styled } from '@mui/material';
import { ReactComponent as Logo } from "../elements/logo.svg";
import { Link } from 'react-router-dom';

const StyledAppBar = styled(AppBar)<AppBarProps>(({ theme }) => ({
    position: 'fixed',
    backgroundColor: theme.palette.primary.dark,
    zIndex: theme.zIndex.drawer + 1
}));

export default function TopBar() {
    const dispatch = useDispatch();
    const handleToggleSideBar = () => dispatch(toggleSidebar());
    // const handleAppModalMenuBar = () => dispatch(toggleAppModalMenu());

    return (
        <StyledAppBar>
            <Toolbar variant='regular'>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleToggleSideBar}>
                    <MenuIcon />
                </IconButton>
                <Box sx={{flexGrow: 1}} />
                   <Link to="/"><Logo width='92px' height='52px' /></Link>
                {/*}
                <Box sx={{display: 'flex', alignItems: 'center', gap: '6px'}}>
                    <IconButton color="inherit" aria-label="edit">
                        <EditIcon />
                    </IconButton>
                    <IconButton color="inherit" aria-label="settings" onClick={handleAppModalMenuBar}>
                        <AppsIcon />
                    </IconButton>
                </Box>
                */}
            </Toolbar>
        </StyledAppBar>
    );
};
