import MarkerProps from "./MarkerProps";

export function TeamLogoMarker(props: MarkerProps) {
    const {
        x = 0,
        y = 0,
        color = 'white'
    } = props;
    return <svg x={x - 16} y={y - 16} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 5L7 9V15C7 20.55 10.84 25.74 16 27C21.16 25.74 25 20.55 25 15V9L16 5Z" fill={color}/>
    </svg>
}