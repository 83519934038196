import { Box, Typography } from "@mui/material";
import DistributionChart from "../components/charts/DistributionChart";
import { DefaultCustomColors } from "../components/theme";
import RadarChart from "../components/charts/RadarChart";
import { Chart } from "../components/charts/Chart";
import { GoalMarker } from "../components/charts/markers/Goal";
import { CardMarker } from "../components/charts/markers/Card";
import ScatterChart from "../components/charts/ScatterChart";
import * as d3 from 'd3';
import { TeamLogoMarker } from "../components/charts/markers/TeamLogo";
import { PredictionMarker } from "../components/charts/markers/Prediction";
import MatchMomentumChart from "../components/charts/MatchMomentumChart";

export default function DemoPage() {
    const colors = DefaultCustomColors;

    const x = [0, 90/5, 2*90/5, 3*90/5, 4*90/5, 90];

    const distributionChartData = {
        "plot_type": "distribution",
        "title": "N. Jackson Aerial Threat Distribution",
        "subtitle": "Premier League",
        "target_type": "player",
        "kpi_type": "metric",
        "data": {
            "target_labels": [
                "Pablo Sarabia",
                "D. Welbeck",
                "A. Lallana",
                "M. Antonio",
                "D. Ings",
                "J. Rodriguez",
                "C. Wood",
                "C. Wilson",
                "Son Heung-Min",
                "A. Doucouré",
                "R. Jiménez",
                "A. Martial",
                "D. Origi",
                "L. Trossard",
                "C. Woodrow",
                "J. McGinn",
                "N. Maupay",
                "Andreas Pereira",
                "Bruno Fernandes",
                "I. Toney",
                "Y. Tielemans",
                "E. Ünal",
                "C. Morris",
                "T. Awoniyi",
                "Y. Wissa",
                "D. Solanke",
                "O. McBurnie",
                "D. Calvert-Lewin",
                "J. Bowen",
                "O. Watkins",
                "M. Ødegaard",
                "Gabriel Jesus",
                "Diogo Jota",
                "E. Nketiah",
                "C. Nkunku",
                "O. Édouard",
                "Hwang Hee-Chan",
                "J. Mateta",
                "Richarlison",
                "J. Bellegarde",
                "S. McTominay",
                "M. Rashford",
                "A. Isak",
                "J. Moder",
                "M. Diaby",
                "C. Gakpo",
                "K. Havertz",
                "E. Haaland",
                "Carlos Vinícius",
                "R. Brewster",
                "M. Gibbs-White",
                "E. Adebayo",
                "B. Mbeumo",
                "D. Kulusevski",
                "Rodrigo Muniz",
                "B. Brereton Díaz",
                "Pedro Neto",
                "Matheus Cunha",
                "C. Archer",
                "J. McAtee",
                "L. Foster",
                "A. Broja",
                "D. Núñez",
                "J. Álvarez",
                "C. Palmer",
                "A. Elanga",
                "R. Højlund",
                "Beto",
                "Ansu Fati",
                "K. Lewis-Potter",
                "João Pedro",
                "J. Durán",
                "Z. Amdouni",
                "E. Ferguson",
                "W. Odobert",
                "N. Jackson",
                "D. Fofana",
                "B. Traoré",
                "W. Osula"
            ],
            "kpi_names": [
                "Aerials won",
                "Aerials won %",
                "Attacking aerials won",
                "Attacking aerials won %",
                "Headed plays"
            ],
            "focal_target_indices": [
                75
            ],
            "value_range": [
                -4.0,
                4.0
            ],
            "plot_values": [
                [
                    -0.05433636518770963,
                    -0.1043067850830761,
                    0.8606810763571573,
                    0.3511975726302167,
                    0.8410045318598289
                ],
                [
                    0.19064914228303068,
                    0.8478292285925235,
                    -0.09110417097752997,
                    1.1915077935087044,
                    0.0387507326058733
                ],
                [
                    0.2452528531196325,
                    1.5261129869155516,
                    0.22589412172657505,
                    0.27840712920053684,
                    -0.2859225644523867
                ],
                [
                    0.04946333296033289,
                    1.4050033873603436,
                    0.11468474346630678,
                    0.48863800616985476,
                    0.48210779302657736
                ],
                [
                    -0.18652884981299325,
                    0.011238136954582634,
                    -0.215863849465211,
                    0.030206921534756986,
                    -0.5209784949217721
                ],
                [
                    -0.8073617301269548,
                    -1.805959864175157,
                    0.4306560940992619,
                    -1.96655260215496,
                    -0.28936685717999
                ],
                [
                    0.414816801298912,
                    -0.36355437438617005,
                    -0.05125394773546399,
                    -0.36917037576531464,
                    -0.29979089391651287
                ],
                [
                    1.4172976714713164,
                    0.16632878509582188,
                    0.8728244442584311,
                    0.5402639612990374,
                    0.6879062414732618
                ],
                [
                    0.1325307365518241,
                    1.5501962974862729,
                    -0.4500858599353524,
                    -0.8477440126228268,
                    -0.41757863750491453
                ],
                [
                    -1.0642554559457966,
                    -1.8581840910054457,
                    -0.46109590180451315,
                    0.7799269915647474,
                    0.3578272163290318
                ],
                [
                    0.12733216397664723,
                    -0.4180636641424838,
                    -0.9991285106324985,
                    -0.9134021490950279,
                    -1.0720342620132879
                ],
                [
                    0.0019885977908724608,
                    -0.3973341352252902,
                    -1.0404081628152162,
                    0.6043478225263976,
                    -0.8348242071776476
                ],
                [
                    -1.1959495347659492,
                    -0.9591227331967838,
                    0.5811523743508336,
                    -1.5439586327274868,
                    0.01109230886796846
                ],
                [
                    1.048063217854513,
                    -0.8948040055311953,
                    -0.13737076516026903,
                    -1.3598296442938247,
                    0.7223956539896547
                ],
                [
                    -0.465595762444081,
                    -1.5542207084236552,
                    -0.4698407338068769,
                    0.15864038648701476,
                    -0.5311320136747016
                ],
                [
                    -0.21150768460143157,
                    0.13602107280555945,
                    -0.7588799146381491,
                    0.617946957380076,
                    -0.49132396790665306
                ],
                [
                    0.20587692016601536,
                    -1.145141493598706,
                    -0.010626473806977019,
                    -3.142453419090365,
                    -0.3682522772900591
                ],
                [
                    0.15112293243141986,
                    -0.8110230629034612,
                    -0.6053647486347074,
                    -0.45653095171092956,
                    -0.7076853780814818
                ],
                [
                    0.32678148646217825,
                    -0.757938332378183,
                    -0.07742808584887013,
                    0.15745910559902876,
                    -0.5863231260396241
                ],
                [
                    -0.1950963421649305,
                    -0.38593383680826604,
                    -0.7465724633420436,
                    1.6067788152749949,
                    -0.7961287037667447
                ],
                [
                    -0.1382443374265199,
                    0.009682992730749518,
                    -0.3568486287528304,
                    -0.25513462303484996,
                    -0.14002000562870962
                ],
                [
                    1.655354487362459,
                    0.25579711456813775,
                    1.3774844695374127,
                    1.4154646134339863,
                    2.5909431398018326
                ],
                [
                    0.9243906929867376,
                    0.9022344517504935,
                    -1.4187395776205873,
                    -0.9626677390409061,
                    -0.19248370967939737
                ],
                [
                    -0.2947389781797929,
                    -0.7772426841392583,
                    0.017260366253074196,
                    1.5112058917987155,
                    -0.1877793858724085
                ],
                [
                    0.01622250839913706,
                    -0.5722529937080669,
                    -0.4667956904190181,
                    0.3695625528828419,
                    -0.11770483908941433
                ],
                [
                    -0.4692369405665371,
                    1.6669098164342797,
                    -0.4256227908344327,
                    -1.5219434042165643,
                    -0.7289064613743826
                ],
                [
                    -0.8233812587635669,
                    -0.050747837443366826,
                    -0.37747388776811164,
                    1.615730204123642,
                    0.33258976806875906
                ],
                [
                    -1.9252240004945556,
                    -0.7089283256621701,
                    -1.3646227970005256,
                    -0.7423859376196852,
                    -1.5768277208473418
                ],
                [
                    0.1620091777931897,
                    0.32167972755022106,
                    0.19000178099837958,
                    2.1646668503916704,
                    0.37954720661254043
                ],
                [
                    -0.258675560259797,
                    0.8045244290983034,
                    -0.6169625590450682,
                    -0.6379754473838015,
                    -0.691376694734882
                ],
                [
                    -1.350427260727366,
                    0.2595369194961833,
                    -0.791771892364824,
                    -0.6151518264685947,
                    1.026810369915182
                ],
                [
                    0.16732716460836766,
                    -1.0801068439284869,
                    -0.28581278714942876,
                    0.9363931453983712,
                    -0.5320731922302381
                ],
                [
                    0.6853757819873383,
                    -0.8973352500737407,
                    -0.7904986471968655,
                    1.0322868774230827,
                    -0.7524304071040602
                ],
                [
                    0.2043132542076228,
                    -0.6996330827372925,
                    1.3149458139205896,
                    -1.1732581935791089,
                    1.2380445158466942
                ],
                [
                    -0.4570977985190803,
                    2.295421790838724,
                    0.604330132031679,
                    0.7316557113074877,
                    0.3727914942690901
                ],
                [
                    0.05251794320584212,
                    0.26217885732358365,
                    -0.8551388600597604,
                    1.9655919790172711,
                    -0.9377790277583161
                ],
                [
                    -1.2500363955155898,
                    1.9843993389502013,
                    -0.8493561876098746,
                    1.3436781753732883,
                    -0.6284696032354434
                ],
                [
                    -0.9812186901397953,
                    -0.009149879916371485,
                    -0.2298504342880357,
                    0.5659258116121857,
                    -0.5673123164513378
                ],
                [
                    0.8615710672790462,
                    -0.9216288808806968,
                    -0.5411700015797604,
                    0.566865175066548,
                    -0.10455725387481635
                ],
                [
                    1.1933573246392175,
                    0.3079324039302443,
                    2.6877543267119948,
                    -0.8817976521016222,
                    3.5629590476822126
                ],
                [
                    -1.213290686972044,
                    0.0293588466952097,
                    1.0134474490818468,
                    0.7700394612294562,
                    0.19090342397143736
                ],
                [
                    -1.1794812410247564,
                    -1.7551002497069959,
                    0.46833147596639846,
                    -0.027095917134016177,
                    1.6252438305587196
                ],
                [
                    1.6805480849290735,
                    2.829246028541823,
                    2.413631945967825,
                    -0.03593538265417613,
                    1.3016826845723481
                ],
                [
                    -0.70816000855229,
                    1.5906893024742204,
                    -1.311557553518063,
                    -0.6049381122666946,
                    -1.0026922484619896
                ],
                [
                    -1.4613563837850374,
                    0.8527183915637782,
                    -1.2026102457826575,
                    -0.6218936962590246,
                    -0.9608771114244401
                ],
                [
                    0.019229864921174483,
                    -0.965243974446436,
                    3.16332996588473,
                    -1.1960442076923083,
                    3.0354320965770527
                ],
                [
                    0.5842381748325465,
                    -0.532224730143644,
                    0.5909116910924042,
                    -0.18181754046390367,
                    0.16655258556657607
                ],
                [
                    0.7965155961328428,
                    1.7166216047376501,
                    1.1405853934410046,
                    -1.5773911071568285,
                    -0.8281287256464425
                ],
                [
                    0.7827499897789373,
                    -0.13537020217371082,
                    1.1553077911386056,
                    0.28130243511421094,
                    1.6773580522822364
                ],
                [
                    -0.38299310256981295,
                    0.8406465253354435,
                    -0.8609996127869288,
                    0.3302607318697552,
                    -1.2308683105790734
                ],
                [
                    -0.902734854395962,
                    0.9470108939713914,
                    -0.11426272550318715,
                    -0.19460207696247867,
                    -0.5968093529235593
                ],
                [
                    0.17320693569363624,
                    -0.24366458553512785,
                    -0.07182262592291118,
                    1.0143044570057436,
                    -0.5112223261162886
                ],
                [
                    -1.5125309533854063,
                    -0.46846269934247964,
                    -0.8893828271083823,
                    0.3504197762741125,
                    -0.8000453205544793
                ],
                [
                    1.6165189679312397,
                    -0.6479884568281574,
                    -0.14320546225489283,
                    0.544407275569783,
                    0.11217563129787149
                ],
                [
                    1.953364430168961,
                    0.612526843820137,
                    2.0068256659072015,
                    -0.7288727316194084,
                    3.1468276883299247
                ],
                [
                    -0.7935474797629589,
                    -0.471437302824856,
                    -0.7718218489806637,
                    0.36069574749138866,
                    -0.1843456916808123
                ],
                [
                    0.4638002324420004,
                    -2.2213347263592254,
                    0.17698889009946595,
                    0.2790137668887989,
                    -1.5786814633689514
                ],
                [
                    3.48492236343225,
                    1.3084356626127092,
                    0.9795299037344839,
                    -1.712098010450513,
                    0.1359220293422283
                ],
                [
                    -1.7903576203628309,
                    -0.791817602695222,
                    -0.3281815114522191,
                    0.9518209028234192,
                    -0.13148957164110245
                ],
                [
                    -0.05679960719514311,
                    1.0199198999765675,
                    0.8229497895410839,
                    0.5470985302696494,
                    0.6447178234241365
                ],
                [
                    0.109237399745096,
                    0.6600276242067555,
                    -1.0872785988198985,
                    -2.291593496276661,
                    -0.6769221135343382
                ],
                [
                    -0.6277252933120344,
                    0.8499556289400728,
                    -0.5003172429166446,
                    -0.43985549314044,
                    0.07456006513011426
                ],
                [
                    -0.8557140885408168,
                    -1.9379017071370117,
                    -0.17651711035216297,
                    0.05154309000859193,
                    -0.4894579800275741
                ],
                [
                    3.288848658897883,
                    0.32703269613333497,
                    1.5713549257442203,
                    0.23609091571629157,
                    0.8468298257431718
                ],
                [
                    0.5656663065949333,
                    0.005844040774083347,
                    -1.1058767947262653,
                    -0.3472333642962966,
                    -0.609665154844531
                ],
                [
                    0.5866106458887211,
                    0.5487380490850605,
                    -1.0541507121479232,
                    0.5095639748439255,
                    -0.4572689982326862
                ],
                [
                    -0.34292173149048727,
                    0.31334511276740434,
                    -0.7397815632508183,
                    0.26545286134515944,
                    -0.7482517229168727
                ],
                [
                    1.1414799226898713,
                    -0.8311946795790135,
                    0.4064811056229788,
                    0.5715999174984694,
                    -0.24277631093427096
                ],
                [
                    0.7852801246540501,
                    -0.01930996223435453,
                    -0.10518966661117454,
                    -0.8887894449875668,
                    0.1649025881706811
                ],
                [
                    0.10932683366796751,
                    -0.27366485395673246,
                    3.0575761017039564,
                    0.37771656870181275,
                    1.4497191113219314
                ],
                [
                    -0.8724108825910324,
                    0.039381013565660435,
                    -0.7343990962513718,
                    -0.7670352766469104,
                    -1.1027473835287256
                ],
                [
                    -1.4913009927450411,
                    0.6784596073478081,
                    -1.053735015226214,
                    -0.650486831545219,
                    -0.15747587768534602
                ],
                [
                    -0.03512550730196738,
                    0.02356044090328817,
                    -0.07824225690342149,
                    0.3965533889731933,
                    0.5996165025272858
                ],
                [
                    -1.0569964575967787,
                    -0.5497775107828994,
                    -0.22708920927801413,
                    -0.5895205922245795,
                    -1.0186964834678782
                ],
                [
                    -0.665095453039131,
                    0.12805844323509957,
                    -0.659546720146065,
                    -0.5060476032324241,
                    -0.10664533776296722
                ],
                [
                    -0.27231009099004444,
                    -0.9462326288679861,
                    0.4074323386068357,
                    -0.09067581575314125,
                    -0.10210954137931036
                ],
                [
                    -0.25423535984033124,
                    -0.16510629183901265,
                    1.2804593983544827,
                    0.098087201029321,
                    0.7001708273934046
                ],
                [
                    0.10956536669904997,
                    0.4245396081346836,
                    -0.44596132592207455,
                    1.9550624716717808,
                    -0.36118538393102984
                ],
                [
                    0.11927558316047722,
                    -0.3306989729037864,
                    -0.7851955194444686,
                    -0.07349861366086669,
                    -0.25996037410843786
                ]
            ],
            "hover_strings": [
                [
                    "Player: Pablo Sarabia\nRank: 43/79\nValue: 2.23",
                    "Player: Pablo Sarabia\nRank: 43/79\nValue: 48.47",
                    "Player: Pablo Sarabia\nRank: 15/79\nValue: 3.27",
                    "Player: Pablo Sarabia\nRank: 31/79\nValue: 57.48",
                    "Player: Pablo Sarabia\nRank: 12/79\nValue: 3.48"
                ],
                [
                    "Player: D. Welbeck\nRank: 26/79\nValue: 2.42",
                    "Player: D. Welbeck\nRank: 16/79\nValue: 61.39",
                    "Player: D. Welbeck\nRank: 34/79\nValue: 2.24",
                    "Player: D. Welbeck\nRank: 9/79\nValue: 68.47",
                    "Player: D. Welbeck\nRank: 29/79\nValue: 2.47"
                ],
                [
                    "Player: A. Lallana\nRank: 23/79\nValue: 2.46",
                    "Player: A. Lallana\nRank: 8/79\nValue: 70.60",
                    "Player: A. Lallana\nRank: 24/79\nValue: 2.59",
                    "Player: A. Lallana\nRank: 36/79\nValue: 56.52",
                    "Player: A. Lallana\nRank: 43/79\nValue: 2.07"
                ],
                [
                    "Player: M. Antonio\nRank: 38/79\nValue: 2.31",
                    "Player: M. Antonio\nRank: 9/79\nValue: 68.96",
                    "Player: M. Antonio\nRank: 27/79\nValue: 2.47",
                    "Player: M. Antonio\nRank: 26/79\nValue: 59.27",
                    "Player: M. Antonio\nRank: 18/79\nValue: 3.03"
                ],
                [
                    "Player: D. Ings\nRank: 46/79\nValue: 2.12",
                    "Player: D. Ings\nRank: 37/79\nValue: 50.04",
                    "Player: D. Ings\nRank: 40/79\nValue: 2.11",
                    "Player: D. Ings\nRank: 43/79\nValue: 53.28",
                    "Player: D. Ings\nRank: 53/79\nValue: 1.77"
                ],
                [
                    "Player: J. Rodriguez\nRank: 62/79\nValue: 1.64",
                    "Player: J. Rodriguez\nRank: 76/79\nValue: 25.36",
                    "Player: J. Rodriguez\nRank: 21/79\nValue: 2.81",
                    "Player: J. Rodriguez\nRank: 77/79\nValue: 27.16",
                    "Player: J. Rodriguez\nRank: 44/79\nValue: 2.06"
                ],
                [
                    "Player: C. Wood\nRank: 21/79\nValue: 2.59",
                    "Player: C. Wood\nRank: 49/79\nValue: 44.95",
                    "Player: C. Wood\nRank: 30/79\nValue: 2.29",
                    "Player: C. Wood\nRank: 52/79\nValue: 48.05",
                    "Player: C. Wood\nRank: 45/79\nValue: 2.05"
                ],
                [
                    "Player: C. Wilson\nRank: 7/79\nValue: 3.37",
                    "Player: C. Wilson\nRank: 31/79\nValue: 52.14",
                    "Player: C. Wilson\nRank: 14/79\nValue: 3.29",
                    "Player: C. Wilson\nRank: 24/79\nValue: 59.95",
                    "Player: C. Wilson\nRank: 15/79\nValue: 3.29"
                ],
                [
                    "Player: Son Heung-Min\nRank: 31/79\nValue: 2.37",
                    "Player: Son Heung-Min\nRank: 7/79\nValue: 70.93",
                    "Player: Son Heung-Min\nRank: 49/79\nValue: 1.85",
                    "Player: Son Heung-Min\nRank: 65/79\nValue: 41.79",
                    "Player: Son Heung-Min\nRank: 48/79\nValue: 1.90"
                ],
                [
                    "Player: A. Doucouré\nRank: 69/79\nValue: 1.44",
                    "Player: A. Doucouré\nRank: 77/79\nValue: 24.65",
                    "Player: A. Doucouré\nRank: 50/79\nValue: 1.84",
                    "Player: A. Doucouré\nRank: 14/79\nValue: 63.08",
                    "Player: A. Doucouré\nRank: 21/79\nValue: 2.87"
                ],
                [
                    "Player: R. Jiménez\nRank: 32/79\nValue: 2.37",
                    "Player: R. Jiménez\nRank: 52/79\nValue: 44.21",
                    "Player: R. Jiménez\nRank: 70/79\nValue: 1.26",
                    "Player: R. Jiménez\nRank: 68/79\nValue: 40.93",
                    "Player: R. Jiménez\nRank: 75/79\nValue: 1.09"
                ],
                [
                    "Player: A. Martial\nRank: 41/79\nValue: 2.27",
                    "Player: A. Martial\nRank: 51/79\nValue: 44.49",
                    "Player: A. Martial\nRank: 71/79\nValue: 1.21",
                    "Player: A. Martial\nRank: 18/79\nValue: 60.79",
                    "Player: A. Martial\nRank: 70/79\nValue: 1.38"
                ],
                [
                    "Player: D. Origi\nRank: 71/79\nValue: 1.34",
                    "Player: D. Origi\nRank: 70/79\nValue: 36.86",
                    "Player: D. Origi\nRank: 19/79\nValue: 2.97",
                    "Player: D. Origi\nRank: 74/79\nValue: 32.69",
                    "Player: D. Origi\nRank: 30/79\nValue: 2.44"
                ],
                [
                    "Player: L. Trossard\nRank: 10/79\nValue: 3.08",
                    "Player: L. Trossard\nRank: 66/79\nValue: 37.73",
                    "Player: L. Trossard\nRank: 37/79\nValue: 2.19",
                    "Player: L. Trossard\nRank: 72/79\nValue: 35.10",
                    "Player: L. Trossard\nRank: 13/79\nValue: 3.33"
                ],
                [
                    "Player: C. Woodrow\nRank: 56/79\nValue: 1.91",
                    "Player: C. Woodrow\nRank: 74/79\nValue: 28.78",
                    "Player: C. Woodrow\nRank: 52/79\nValue: 1.83",
                    "Player: C. Woodrow\nRank: 39/79\nValue: 54.96",
                    "Player: C. Woodrow\nRank: 54/79\nValue: 1.76"
                ],
                [
                    "Player: J. McGinn\nRank: 48/79\nValue: 2.11",
                    "Player: J. McGinn\nRank: 32/79\nValue: 51.73",
                    "Player: J. McGinn\nRank: 61/79\nValue: 1.52",
                    "Player: J. McGinn\nRank: 17/79\nValue: 60.97",
                    "Player: J. McGinn\nRank: 51/79\nValue: 1.81"
                ],
                [
                    "Player: N. Maupay\nRank: 24/79\nValue: 2.43",
                    "Player: N. Maupay\nRank: 73/79\nValue: 34.33",
                    "Player: N. Maupay\nRank: 29/79\nValue: 2.33",
                    "Player: N. Maupay\nRank: 79/79\nValue: 11.78",
                    "Player: N. Maupay\nRank: 47/79\nValue: 1.97"
                ],
                [
                    "Player: Andreas Pereira\nRank: 30/79\nValue: 2.39",
                    "Player: Andreas Pereira\nRank: 64/79\nValue: 38.87",
                    "Player: Andreas Pereira\nRank: 55/79\nValue: 1.69",
                    "Player: Andreas Pereira\nRank: 54/79\nValue: 46.91",
                    "Player: Andreas Pereira\nRank: 63/79\nValue: 1.54"
                ],
                [
                    "Player: Bruno Fernandes\nRank: 22/79\nValue: 2.52",
                    "Player: Bruno Fernandes\nRank: 61/79\nValue: 39.59",
                    "Player: Bruno Fernandes\nRank: 32/79\nValue: 2.26",
                    "Player: Bruno Fernandes\nRank: 40/79\nValue: 54.94",
                    "Player: Bruno Fernandes\nRank: 57/79\nValue: 1.69"
                ],
                [
                    "Player: I. Toney\nRank: 47/79\nValue: 2.12",
                    "Player: I. Toney\nRank: 50/79\nValue: 44.64",
                    "Player: I. Toney\nRank: 60/79\nValue: 1.53",
                    "Player: I. Toney\nRank: 5/79\nValue: 73.90",
                    "Player: I. Toney\nRank: 67/79\nValue: 1.43"
                ],
                [
                    "Player: Y. Tielemans\nRank: 45/79\nValue: 2.16",
                    "Player: Y. Tielemans\nRank: 38/79\nValue: 50.01",
                    "Player: Y. Tielemans\nRank: 45/79\nValue: 1.95",
                    "Player: Y. Tielemans\nRank: 50/79\nValue: 49.55",
                    "Player: Y. Tielemans\nRank: 36/79\nValue: 2.25"
                ],
                [
                    "Player: E. Ünal\nRank: 5/79\nValue: 3.56",
                    "Player: E. Ünal\nRank: 30/79\nValue: 53.36",
                    "Player: E. Ünal\nRank: 7/79\nValue: 3.83",
                    "Player: E. Ünal\nRank: 7/79\nValue: 71.40",
                    "Player: E. Ünal\nRank: 4/79\nValue: 5.66"
                ],
                [
                    "Player: C. Morris\nRank: 11/79\nValue: 2.99",
                    "Player: C. Morris\nRank: 13/79\nValue: 62.13",
                    "Player: C. Morris\nRank: 79/79\nValue: 0.81",
                    "Player: C. Morris\nRank: 69/79\nValue: 40.29",
                    "Player: C. Morris\nRank: 40/79\nValue: 2.19"
                ],
                [
                    "Player: T. Awoniyi\nRank: 52/79\nValue: 2.04",
                    "Player: T. Awoniyi\nRank: 62/79\nValue: 39.33",
                    "Player: T. Awoniyi\nRank: 28/79\nValue: 2.36",
                    "Player: T. Awoniyi\nRank: 6/79\nValue: 72.65",
                    "Player: T. Awoniyi\nRank: 39/79\nValue: 2.19"
                ],
                [
                    "Player: Y. Wissa\nRank: 40/79\nValue: 2.28",
                    "Player: Y. Wissa\nRank: 57/79\nValue: 42.11",
                    "Player: Y. Wissa\nRank: 51/79\nValue: 1.84",
                    "Player: Y. Wissa\nRank: 29/79\nValue: 57.72",
                    "Player: Y. Wissa\nRank: 34/79\nValue: 2.28"
                ],
                [
                    "Player: D. Solanke\nRank: 57/79\nValue: 1.91",
                    "Player: D. Solanke\nRank: 5/79\nValue: 72.52",
                    "Player: D. Solanke\nRank: 47/79\nValue: 1.88",
                    "Player: D. Solanke\nRank: 73/79\nValue: 32.98",
                    "Player: D. Solanke\nRank: 64/79\nValue: 1.51"
                ],
                [
                    "Player: O. McBurnie\nRank: 63/79\nValue: 1.63",
                    "Player: O. McBurnie\nRank: 42/79\nValue: 49.19",
                    "Player: O. McBurnie\nRank: 46/79\nValue: 1.93",
                    "Player: O. McBurnie\nRank: 4/79\nValue: 74.02",
                    "Player: O. McBurnie\nRank: 22/79\nValue: 2.84"
                ],
                [
                    "Player: D. Calvert-Lewin\nRank: 79/79\nValue: 0.77",
                    "Player: D. Calvert-Lewin\nRank: 60/79\nValue: 40.26",
                    "Player: D. Calvert-Lewin\nRank: 78/79\nValue: 0.86",
                    "Player: D. Calvert-Lewin\nRank: 63/79\nValue: 43.17",
                    "Player: D. Calvert-Lewin\nRank: 78/79\nValue: 0.45"
                ],
                [
                    "Player: J. Bowen\nRank: 29/79\nValue: 2.40",
                    "Player: J. Bowen\nRank: 25/79\nValue: 54.25",
                    "Player: J. Bowen\nRank: 25/79\nValue: 2.55",
                    "Player: J. Bowen\nRank: 1/79\nValue: 81.20",
                    "Player: J. Bowen\nRank: 19/79\nValue: 2.90"
                ],
                [
                    "Player: O. Watkins\nRank: 50/79\nValue: 2.07",
                    "Player: O. Watkins\nRank: 18/79\nValue: 60.81",
                    "Player: O. Watkins\nRank: 56/79\nValue: 1.67",
                    "Player: O. Watkins\nRank: 60/79\nValue: 44.54",
                    "Player: O. Watkins\nRank: 62/79\nValue: 1.56"
                ],
                [
                    "Player: M. Ødegaard\nRank: 74/79\nValue: 1.22",
                    "Player: M. Ødegaard\nRank: 29/79\nValue: 53.41",
                    "Player: M. Ødegaard\nRank: 65/79\nValue: 1.48",
                    "Player: M. Ødegaard\nRank: 58/79\nValue: 44.84",
                    "Player: M. Ødegaard\nRank: 10/79\nValue: 3.71"
                ],
                [
                    "Player: Gabriel Jesus\nRank: 28/79\nValue: 2.40",
                    "Player: Gabriel Jesus\nRank: 72/79\nValue: 35.22",
                    "Player: Gabriel Jesus\nRank: 43/79\nValue: 2.03",
                    "Player: Gabriel Jesus\nRank: 13/79\nValue: 65.13",
                    "Player: Gabriel Jesus\nRank: 55/79\nValue: 1.76"
                ],
                [
                    "Player: Diogo Jota\nRank: 16/79\nValue: 2.80",
                    "Player: Diogo Jota\nRank: 67/79\nValue: 37.70",
                    "Player: Diogo Jota\nRank: 64/79\nValue: 1.49",
                    "Player: Diogo Jota\nRank: 10/79\nValue: 66.38",
                    "Player: Diogo Jota\nRank: 66/79\nValue: 1.49"
                ],
                [
                    "Player: E. Nketiah\nRank: 25/79\nValue: 2.43",
                    "Player: E. Nketiah\nRank: 59/79\nValue: 40.38",
                    "Player: E. Nketiah\nRank: 8/79\nValue: 3.76",
                    "Player: E. Nketiah\nRank: 70/79\nValue: 37.54",
                    "Player: E. Nketiah\nRank: 9/79\nValue: 3.97"
                ],
                [
                    "Player: C. Nkunku\nRank: 55/79\nValue: 1.91",
                    "Player: C. Nkunku\nRank: 2/79\nValue: 81.05",
                    "Player: C. Nkunku\nRank: 17/79\nValue: 3.00",
                    "Player: C. Nkunku\nRank: 16/79\nValue: 62.45",
                    "Player: C. Nkunku\nRank: 20/79\nValue: 2.89"
                ],
                [
                    "Player: O. Édouard\nRank: 37/79\nValue: 2.31",
                    "Player: O. Édouard\nRank: 28/79\nValue: 53.44",
                    "Player: O. Édouard\nRank: 67/79\nValue: 1.42",
                    "Player: O. Édouard\nRank: 2/79\nValue: 78.59",
                    "Player: O. Édouard\nRank: 71/79\nValue: 1.25"
                ],
                [
                    "Player: Hwang Hee-Chan\nRank: 73/79\nValue: 1.30",
                    "Player: Hwang Hee-Chan\nRank: 3/79\nValue: 76.83",
                    "Player: Hwang Hee-Chan\nRank: 66/79\nValue: 1.42",
                    "Player: Hwang Hee-Chan\nRank: 8/79\nValue: 70.46",
                    "Player: Hwang Hee-Chan\nRank: 60/79\nValue: 1.64"
                ],
                [
                    "Player: J. Mateta\nRank: 67/79\nValue: 1.51",
                    "Player: J. Mateta\nRank: 40/79\nValue: 49.76",
                    "Player: J. Mateta\nRank: 42/79\nValue: 2.09",
                    "Player: J. Mateta\nRank: 21/79\nValue: 60.28",
                    "Player: J. Mateta\nRank: 56/79\nValue: 1.72"
                ],
                [
                    "Player: Richarlison\nRank: 12/79\nValue: 2.94",
                    "Player: Richarlison\nRank: 68/79\nValue: 37.37",
                    "Player: Richarlison\nRank: 54/79\nValue: 1.76",
                    "Player: Richarlison\nRank: 20/79\nValue: 60.30",
                    "Player: Richarlison\nRank: 32/79\nValue: 2.30"
                ],
                [
                    "Player: J. Bellegarde\nRank: 8/79\nValue: 3.20",
                    "Player: J. Bellegarde\nRank: 27/79\nValue: 54.06",
                    "Player: J. Bellegarde\nRank: 3/79\nValue: 5.25",
                    "Player: J. Bellegarde\nRank: 66/79\nValue: 41.35",
                    "Player: J. Bellegarde\nRank: 1/79\nValue: 6.88"
                ],
                [
                    "Player: S. McTominay\nRank: 72/79\nValue: 1.33",
                    "Player: S. McTominay\nRank: 35/79\nValue: 50.28",
                    "Player: S. McTominay\nRank: 12/79\nValue: 3.44",
                    "Player: S. McTominay\nRank: 15/79\nValue: 62.95",
                    "Player: S. McTominay\nRank: 23/79\nValue: 2.66"
                ],
                [
                    "Player: M. Rashford\nRank: 70/79\nValue: 1.35",
                    "Player: M. Rashford\nRank: 75/79\nValue: 26.05",
                    "Player: M. Rashford\nRank: 20/79\nValue: 2.85",
                    "Player: M. Rashford\nRank: 44/79\nValue: 52.53",
                    "Player: M. Rashford\nRank: 6/79\nValue: 4.46"
                ],
                [
                    "Player: A. Isak\nRank: 4/79\nValue: 3.57",
                    "Player: A. Isak\nRank: 1/79\nValue: 88.30",
                    "Player: A. Isak\nRank: 4/79\nValue: 4.95",
                    "Player: A. Isak\nRank: 45/79\nValue: 52.41",
                    "Player: A. Isak\nRank: 8/79\nValue: 4.05"
                ],
                [
                    "Player: J. Moder\nRank: 60/79\nValue: 1.72",
                    "Player: J. Moder\nRank: 6/79\nValue: 71.48",
                    "Player: J. Moder\nRank: 77/79\nValue: 0.92",
                    "Player: J. Moder\nRank: 57/79\nValue: 44.97",
                    "Player: J. Moder\nRank: 73/79\nValue: 1.17"
                ],
                [
                    "Player: M. Diaby\nRank: 75/79\nValue: 1.13",
                    "Player: M. Diaby\nRank: 14/79\nValue: 61.46",
                    "Player: M. Diaby\nRank: 76/79\nValue: 1.04",
                    "Player: M. Diaby\nRank: 59/79\nValue: 44.75",
                    "Player: M. Diaby\nRank: 72/79\nValue: 1.22"
                ],
                [
                    "Player: C. Gakpo\nRank: 39/79\nValue: 2.28",
                    "Player: C. Gakpo\nRank: 71/79\nValue: 36.78",
                    "Player: C. Gakpo\nRank: 1/79\nValue: 5.77",
                    "Player: C. Gakpo\nRank: 71/79\nValue: 37.24",
                    "Player: C. Gakpo\nRank: 3/79\nValue: 6.22"
                ],
                [
                    "Player: K. Havertz\nRank: 18/79\nValue: 2.72",
                    "Player: K. Havertz\nRank: 55/79\nValue: 42.66",
                    "Player: K. Havertz\nRank: 18/79\nValue: 2.98",
                    "Player: K. Havertz\nRank: 48/79\nValue: 50.50",
                    "Player: K. Havertz\nRank: 24/79\nValue: 2.63"
                ],
                [
                    "Player: E. Haaland\nRank: 13/79\nValue: 2.89",
                    "Player: E. Haaland\nRank: 4/79\nValue: 73.19",
                    "Player: E. Haaland\nRank: 11/79\nValue: 3.58",
                    "Player: E. Haaland\nRank: 75/79\nValue: 32.25",
                    "Player: E. Haaland\nRank: 69/79\nValue: 1.39"
                ],
                [
                    "Player: Carlos Vinícius\nRank: 15/79\nValue: 2.88",
                    "Player: Carlos Vinícius\nRank: 44/79\nValue: 48.05",
                    "Player: Carlos Vinícius\nRank: 10/79\nValue: 3.59",
                    "Player: Carlos Vinícius\nRank: 34/79\nValue: 56.56",
                    "Player: Carlos Vinícius\nRank: 5/79\nValue: 4.52"
                ],
                [
                    "Player: R. Brewster\nRank: 54/79\nValue: 1.97",
                    "Player: R. Brewster\nRank: 17/79\nValue: 61.30",
                    "Player: R. Brewster\nRank: 68/79\nValue: 1.41",
                    "Player: R. Brewster\nRank: 33/79\nValue: 57.20",
                    "Player: R. Brewster\nRank: 77/79\nValue: 0.89"
                ],
                [
                    "Player: M. Gibbs-White\nRank: 66/79\nValue: 1.57",
                    "Player: M. Gibbs-White\nRank: 12/79\nValue: 62.74",
                    "Player: M. Gibbs-White\nRank: 36/79\nValue: 2.22",
                    "Player: M. Gibbs-White\nRank: 49/79\nValue: 50.34",
                    "Player: M. Gibbs-White\nRank: 58/79\nValue: 1.68"
                ],
                [
                    "Player: E. Adebayo\nRank: 27/79\nValue: 2.40",
                    "Player: E. Adebayo\nRank: 46/79\nValue: 46.57",
                    "Player: E. Adebayo\nRank: 31/79\nValue: 2.26",
                    "Player: E. Adebayo\nRank: 11/79\nValue: 66.15",
                    "Player: E. Adebayo\nRank: 52/79\nValue: 1.79"
                ],
                [
                    "Player: B. Mbeumo\nRank: 77/79\nValue: 1.09",
                    "Player: B. Mbeumo\nRank: 53/79\nValue: 43.52",
                    "Player: B. Mbeumo\nRank: 69/79\nValue: 1.38",
                    "Player: B. Mbeumo\nRank: 32/79\nValue: 57.47",
                    "Player: B. Mbeumo\nRank: 68/79\nValue: 1.43"
                ],
                [
                    "Player: D. Kulusevski\nRank: 6/79\nValue: 3.53",
                    "Player: D. Kulusevski\nRank: 58/79\nValue: 41.09",
                    "Player: D. Kulusevski\nRank: 38/79\nValue: 2.19",
                    "Player: D. Kulusevski\nRank: 23/79\nValue: 60.00",
                    "Player: D. Kulusevski\nRank: 27/79\nValue: 2.57"
                ],
                [
                    "Player: Rodrigo Muniz\nRank: 3/79\nValue: 3.79",
                    "Player: Rodrigo Muniz\nRank: 21/79\nValue: 58.20",
                    "Player: Rodrigo Muniz\nRank: 5/79\nValue: 4.51",
                    "Player: Rodrigo Muniz\nRank: 62/79\nValue: 43.35",
                    "Player: Rodrigo Muniz\nRank: 2/79\nValue: 6.36"
                ],
                [
                    "Player: B. Brereton Díaz\nRank: 61/79\nValue: 1.65",
                    "Player: B. Brereton Díaz\nRank: 54/79\nValue: 43.48",
                    "Player: B. Brereton Díaz\nRank: 62/79\nValue: 1.51",
                    "Player: B. Brereton Díaz\nRank: 30/79\nValue: 57.60",
                    "Player: B. Brereton Díaz\nRank: 38/79\nValue: 2.20"
                ],
                [
                    "Player: Pedro Neto\nRank: 20/79\nValue: 2.63",
                    "Player: Pedro Neto\nRank: 79/79\nValue: 19.72",
                    "Player: Pedro Neto\nRank: 26/79\nValue: 2.53",
                    "Player: Pedro Neto\nRank: 35/79\nValue: 56.53",
                    "Player: Pedro Neto\nRank: 79/79\nValue: 0.45"
                ],
                [
                    "Player: Matheus Cunha\nRank: 1/79\nValue: 4.98",
                    "Player: Matheus Cunha\nRank: 10/79\nValue: 67.65",
                    "Player: Matheus Cunha\nRank: 13/79\nValue: 3.40",
                    "Player: Matheus Cunha\nRank: 76/79\nValue: 30.49",
                    "Player: Matheus Cunha\nRank: 26/79\nValue: 2.60"
                ],
                [
                    "Player: C. Archer\nRank: 78/79\nValue: 0.88",
                    "Player: C. Archer\nRank: 63/79\nValue: 39.13",
                    "Player: C. Archer\nRank: 44/79\nValue: 1.99",
                    "Player: C. Archer\nRank: 12/79\nValue: 65.33",
                    "Player: C. Archer\nRank: 35/79\nValue: 2.26"
                ],
                [
                    "Player: J. McAtee\nRank: 44/79\nValue: 2.23",
                    "Player: J. McAtee\nRank: 11/79\nValue: 63.73",
                    "Player: J. McAtee\nRank: 16/79\nValue: 3.23",
                    "Player: J. McAtee\nRank: 22/79\nValue: 60.04",
                    "Player: J. McAtee\nRank: 16/79\nValue: 3.23"
                ],
                [
                    "Player: L. Foster\nRank: 36/79\nValue: 2.35",
                    "Player: L. Foster\nRank: 20/79\nValue: 58.85",
                    "Player: L. Foster\nRank: 74/79\nValue: 1.16",
                    "Player: L. Foster\nRank: 78/79\nValue: 22.91",
                    "Player: L. Foster\nRank: 61/79\nValue: 1.58"
                ],
                [
                    "Player: A. Broja\nRank: 58/79\nValue: 1.78",
                    "Player: A. Broja\nRank: 15/79\nValue: 61.42",
                    "Player: A. Broja\nRank: 53/79\nValue: 1.80",
                    "Player: A. Broja\nRank: 53/79\nValue: 47.13",
                    "Player: A. Broja\nRank: 28/79\nValue: 2.52"
                ],
                [
                    "Player: D. Núñez\nRank: 64/79\nValue: 1.60",
                    "Player: D. Núñez\nRank: 78/79\nValue: 23.57",
                    "Player: D. Núñez\nRank: 39/79\nValue: 2.15",
                    "Player: D. Núñez\nRank: 42/79\nValue: 53.56",
                    "Player: D. Núñez\nRank: 50/79\nValue: 1.81"
                ],
                [
                    "Player: J. Álvarez\nRank: 2/79\nValue: 4.82",
                    "Player: J. Álvarez\nRank: 24/79\nValue: 54.32",
                    "Player: J. Álvarez\nRank: 6/79\nValue: 4.04",
                    "Player: J. Álvarez\nRank: 38/79\nValue: 55.97",
                    "Player: J. Álvarez\nRank: 11/79\nValue: 3.48"
                ],
                [
                    "Player: C. Palmer\nRank: 19/79\nValue: 2.71",
                    "Player: C. Palmer\nRank: 39/79\nValue: 49.96",
                    "Player: C. Palmer\nRank: 75/79\nValue: 1.14",
                    "Player: C. Palmer\nRank: 51/79\nValue: 48.34",
                    "Player: C. Palmer\nRank: 59/79\nValue: 1.66"
                ],
                [
                    "Player: A. Elanga\nRank: 17/79\nValue: 2.73",
                    "Player: A. Elanga\nRank: 22/79\nValue: 57.33",
                    "Player: A. Elanga\nRank: 73/79\nValue: 1.20",
                    "Player: A. Elanga\nRank: 25/79\nValue: 59.55",
                    "Player: A. Elanga\nRank: 49/79\nValue: 1.85"
                ],
                [
                    "Player: R. Højlund\nRank: 53/79\nValue: 2.00",
                    "Player: R. Højlund\nRank: 26/79\nValue: 54.14",
                    "Player: R. Højlund\nRank: 59/79\nValue: 1.54",
                    "Player: R. Højlund\nRank: 37/79\nValue: 56.35",
                    "Player: R. Højlund\nRank: 65/79\nValue: 1.49"
                ],
                [
                    "Player: Beto\nRank: 9/79\nValue: 3.16",
                    "Player: Beto\nRank: 65/79\nValue: 38.60",
                    "Player: Beto\nRank: 23/79\nValue: 2.78",
                    "Player: Beto\nRank: 19/79\nValue: 60.36",
                    "Player: Beto\nRank: 41/79\nValue: 2.12"
                ],
                [
                    "Player: Ansu Fati\nRank: 14/79\nValue: 2.88",
                    "Player: Ansu Fati\nRank: 41/79\nValue: 49.62",
                    "Player: Ansu Fati\nRank: 35/79\nValue: 2.23",
                    "Player: Ansu Fati\nRank: 67/79\nValue: 41.26",
                    "Player: Ansu Fati\nRank: 25/79\nValue: 2.63"
                ],
                [
                    "Player: K. Lewis-Potter\nRank: 35/79\nValue: 2.35",
                    "Player: K. Lewis-Potter\nRank: 47/79\nValue: 46.17",
                    "Player: K. Lewis-Potter\nRank: 2/79\nValue: 5.65",
                    "Player: K. Lewis-Potter\nRank: 28/79\nValue: 57.82",
                    "Player: K. Lewis-Potter\nRank: 7/79\nValue: 4.24"
                ],
                [
                    "Player: João Pedro\nRank: 65/79\nValue: 1.59",
                    "Player: João Pedro\nRank: 34/79\nValue: 50.42",
                    "Player: João Pedro\nRank: 58/79\nValue: 1.55",
                    "Player: João Pedro\nRank: 64/79\nValue: 42.85",
                    "Player: João Pedro\nRank: 76/79\nValue: 1.05"
                ],
                [
                    "Player: J. Durán\nRank: 76/79\nValue: 1.11",
                    "Player: J. Durán\nRank: 19/79\nValue: 59.10",
                    "Player: J. Durán\nRank: 72/79\nValue: 1.20",
                    "Player: J. Durán\nRank: 61/79\nValue: 44.37",
                    "Player: J. Durán\nRank: 37/79\nValue: 2.23"
                ],
                [
                    "Player: Z. Amdouni\nRank: 42/79\nValue: 2.24",
                    "Player: Z. Amdouni\nRank: 36/79\nValue: 50.20",
                    "Player: Z. Amdouni\nRank: 33/79\nValue: 2.26",
                    "Player: Z. Amdouni\nRank: 27/79\nValue: 58.07",
                    "Player: Z. Amdouni\nRank: 17/79\nValue: 3.18"
                ],
                [
                    "Player: E. Ferguson\nRank: 68/79\nValue: 1.45",
                    "Player: E. Ferguson\nRank: 56/79\nValue: 42.42",
                    "Player: E. Ferguson\nRank: 41/79\nValue: 2.10",
                    "Player: E. Ferguson\nRank: 56/79\nValue: 45.17",
                    "Player: E. Ferguson\nRank: 74/79\nValue: 1.15"
                ],
                [
                    "Player: W. Odobert\nRank: 59/79\nValue: 1.75",
                    "Player: W. Odobert\nRank: 33/79\nValue: 51.62",
                    "Player: W. Odobert\nRank: 57/79\nValue: 1.63",
                    "Player: W. Odobert\nRank: 55/79\nValue: 46.26",
                    "Player: W. Odobert\nRank: 33/79\nValue: 2.29"
                ],
                [
                    "Player: N. Jackson\nRank: 51/79\nValue: 2.06",
                    "Player: N. Jackson\nRank: 69/79\nValue: 37.04",
                    "Player: N. Jackson\nRank: 22/79\nValue: 2.78",
                    "Player: N. Jackson\nRank: 47/79\nValue: 51.70",
                    "Player: N. Jackson\nRank: 31/79\nValue: 2.30"
                ],
                [
                    "Player: D. Fofana\nRank: 49/79\nValue: 2.07",
                    "Player: D. Fofana\nRank: 45/79\nValue: 47.64",
                    "Player: D. Fofana\nRank: 9/79\nValue: 3.73",
                    "Player: D. Fofana\nRank: 41/79\nValue: 54.17",
                    "Player: D. Fofana\nRank: 14/79\nValue: 3.30"
                ],
                [
                    "Player: B. Traoré\nRank: 34/79\nValue: 2.35",
                    "Player: B. Traoré\nRank: 23/79\nValue: 55.65",
                    "Player: B. Traoré\nRank: 48/79\nValue: 1.86",
                    "Player: B. Traoré\nRank: 3/79\nValue: 78.45",
                    "Player: B. Traoré\nRank: 46/79\nValue: 1.97"
                ],
                [
                    "Player: W. Osula\nRank: 33/79\nValue: 2.36",
                    "Player: W. Osula\nRank: 48/79\nValue: 45.39",
                    "Player: W. Osula\nRank: 63/79\nValue: 1.49",
                    "Player: W. Osula\nRank: 46/79\nValue: 51.92",
                    "Player: W. Osula\nRank: 42/79\nValue: 2.10"
                ]
            ]
        }
    };

    const radarChartData = {
        "plot_type": "radar",
        "title": "N. Jackson Aerial Threat Radar",
        "subtitle": "Premier League",
        "target_type": "player",
        "kpi_type": "metric",
        "data": {
            "target_labels": [
                "N. Jackson"
            ],
            "kpi_names": [
                "Aerials won",
                "Aerials won %",
                "Attacking aerials won",
                "Attacking aerials won %",
                "Headed plays"
            ],
            "value_range": [
                0.0,
                1.0
            ],
            "plot_values": [
                [
                    0.96,
                    0.85,
                    0.87,
                    0.9,
                    0.9
                ]
            ],
            "hover_strings": [
                [
                    "Player: N. Jackson\nRank: 5.00\nValue: 4.09\nZ-Score: 1.85",
                    "Player: N. Jackson\nRank: 16.00\nValue: 66.15\nZ-Score: 1.05",
                    "Player: N. Jackson\nRank: 14.00\nValue: 4.38\nZ-Score: 1.07",
                    "Player: N. Jackson\nRank: 11.00\nValue: 65.79\nZ-Score: 1.30",
                    "Player: N. Jackson\nRank: 11.00\nValue: 4.60\nZ-Score: 1.38"
                ]
            ]
        }
    };

    return <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: colors.background.main,
            color: colors.text.primary,
            gap: '16px',
            padding: '16px',
            height: '100%',
            overflow: 'auto'
        }}>
            <Typography variant="h4">Distribution Chart</Typography>
            <DistributionChart
                colors={colors}
                title={distributionChartData.title}
                subTitle={distributionChartData.subtitle}
                range={distributionChartData.data.value_range as any}
                metrics={distributionChartData.data.kpi_names.map(x => ({display_name: x, name: x, description: x }))}
                series={[{
                    name: 'Other',
                    values: distributionChartData.data.plot_values.filter((_,i) => i !== distributionChartData.data.focal_target_indices[0]),
                    hoverStrings: distributionChartData.data.hover_strings.filter((_,i) => i !== distributionChartData.data.focal_target_indices[0]),
                    marker: {
                    variant: 'line',
                    color: colors.dataPoints[0],
                    }
                }, {
                    name: 'Current',
                    values: [distributionChartData.data.plot_values[distributionChartData.data.focal_target_indices[0]]],
                    hoverStrings: [distributionChartData.data.hover_strings[distributionChartData.data.focal_target_indices[0]]],
                    marker: {
                    variant: 'circle',
                    color: colors.dataPoints[1],
                    }
                }
                ]}
            />

            <Typography variant="h4">Radar Chart</Typography>
            <RadarChart
                colors={colors}
                title={radarChartData.title}
                subTitle={radarChartData.subtitle}
                value_range={[radarChartData.data.value_range[0], radarChartData.data.value_range[0]]}
                axies={radarChartData.data.kpi_names}
                series={radarChartData.data.plot_values.map((data: Array<number>, index: number) => ({
                    name: radarChartData.data.target_labels[index],
                    color: colors.dataPoints[index],
                    hover_strings: radarChartData.data.hover_strings[index]?.map(s => s.replaceAll('\n', '<br/>')),
                    data,
                }))}
            />

            <Typography variant="h4">Full Pitch Chart</Typography>
            <Chart variant="fullpitch" url="/" />

            <Typography variant="h4">Thirds Pitch Chart</Typography>
            <Chart variant="thirdspitch" url="/" />

            <Typography variant="h4">Line Chart</Typography>
            <ScatterChart
                colors={colors}
                title={'Line chart'}
                subTitle="Description"
                series={distributionChartData.data.plot_values.map((d: any) => ({
                    x: x,
                    y: d3.range(6).map(() => d3.randomUniform(1, 10)()),
                    name: d.name,
                })).slice(0,1)}
                xDomain={[0, 90]}
                xAxis={{
                    labels: [
                    {title: "0-15", x: x[0]},
                    {title: "15-30", x: x[1]},
                    {title: "30-HT", x: x[2]},
                    {title: "45-60", x: x[3]},
                    {title: "60-75", x: x[4]},
                    {title: "75-FT", x: x[5]}
                    ],
                    markers:[{
                    position: "top",
                        x: -5,
                        marker: TeamLogoMarker,
                    }, {
                    position: "bottom",
                        x: -5,
                        marker: TeamLogoMarker,
                    }, {
                    position: "top",
                        x: 10,
                        marker: GoalMarker,
                        tooltip: "Goal<br/>Cristiano Ronaldo<br/>10'"
                    }, {
                        position: "top",
                        x: 26,
                        marker: CardMarker,
                        tooltip: "Red Card<br/>Cristiano Ronaldo<br/>26'"
                    }, {
                        position: "bottom",
                        x: 50,
                        marker: GoalMarker,
                        tooltip: "Goal<br/>M.Fenger<br/>50'"
                    }, {
                        position: "bottom",
                        x: 90,
                        marker: GoalMarker,
                        tooltip: "Goal<br/>M.Fenger<br/>90'"
                    }]
                }}
                unit="%"
            />

            <Typography variant="h4">Career Development</Typography>
            <ScatterChart
                colors={colors}
                title={'Line chart'}
                subTitle="Description"
                series={[
                {
                    x: [26,27],
                    y: [4, 2],
                    name: 'Historical',
                    variant: 'line',
                    markers: [null, PredictionMarker]
                },
                {
                    x: [20,21,22,23,24,25,26],
                    y: [0.5,0.75,1.7,2,3,3.5,4],
                    name: 'Prediction',
                    variant: 'line',
                    markers: [TeamLogoMarker, TeamLogoMarker, TeamLogoMarker, TeamLogoMarker, TeamLogoMarker, TeamLogoMarker, TeamLogoMarker]
                }, 
                ]}
                xDomain={[20, 27]}
                xAxis={{
                    labels: [20,21,22,23,24,25,26,27].map(x => ({title: `${x}`, x})),
                }}
                yAxis={{
                    labels: [
                        {title: "Poor", y: 0.5},
                        {title: "Avg.", y: 4.5/2},
                        {title: "Good", y: 4},
                    ]
                }}
                showValues={false}
                showLegends={true}
            />

            <Typography variant="h4">xG race</Typography>
            <ScatterChart
                colors={colors}
                title={'Line chart'}
                subTitle="Description"
                series={[
                    {
                    x: [0, ...d3.range(8).map(() => d3.randomInt(1, 89)()), 90].sort((a, b) => a-b),
                    y: [0.001, ...d3.range(8).map(() => d3.randomUniform(0, 1)()), 1].sort((a, b) => a-b),
                    name: 'Home Team',
                    variant: 'area',
                    opacity: .75,
                    markers: [null, null, null, GoalMarker]
                },
                {
                    x: [0, ...d3.range(8).map(() => d3.randomInt(1, 89)()), 90].sort((a, b) => a-b),
                    y: [0.001, ...d3.range(8).map(() => d3.randomUniform(0, 1)()), 1].sort((a, b) => a-b),
                    name: 'Away Team',
                    variant: 'area',
                    opacity: .35,
                    markers: [null, null, null, null, null, null, GoalMarker]
                }
                ]}
                xDomain={[0, 90]}
                xAxis={{
                    labels: [
                    {title: "0'", x: 0},
                    {title: "HT", x: 45},
                    {title: "FT", x: 90},
                    ],
                }}
                yAxis={{
                    labels: [
                        {title: "0", y: 0},
                        {title: "0.5", y: 0.5},
                        {title: "1.0", y: 0.9},
                    ]
                }}
                showValues={false}
                showLegends={true}
            />

        <Typography variant="h4">Match momentum</Typography>
        <MatchMomentumChart
            title='Who dominated the match?'
            subTitle={'Sub Title'}
            width={'100%'}
            height={'100%'}
            data={{
                homeTeam: {
                    values: [
                       // period 1
                        d3.range(45).reduce((acc, value) => {
                            acc[value] = d3.randomUniform(0, 1)();
                            return acc;
                        }, {} as Record<number, number>),
                        // period 2
                        d3.range(45).reduce((acc, value) => {
                            acc[45+value] = d3.randomUniform(0, 1)();
                            return acc;
                        }, {} as Record<number, number>)
                    ],
                    goals: [
                        {period: 1, minute: 10}
                    ],
                    redCards: [
                        {period: 1, minute: 21}
                    ]
                },
                awayTeam: {
                    values: [
                        // period 1
                        d3.range(45).reduce((acc, value) => {
                            acc[value] = d3.randomUniform(0, 1)();
                            return acc;
                        }, {} as Record<number, number>),
                        // period 2
                        d3.range(45).reduce((acc, value) => {
                            acc[45+value] = d3.randomUniform(0, 1)();
                            return acc;
                        }, {} as Record<number, number>)
                    ],
                    goals: [
                        {period: 1, minute: 32},
                        {period: 2, minute: 47}
                    ]
                }
            }}
        />

        <br/><br/><br/><br/><br/><br/>
    </Box>;
}