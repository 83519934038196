import { SVGTextElementAttributes } from "react";

interface SvgTextWrapProps extends SVGTextElementAttributes<SVGTextElement> {
    text: string;
    lineHeight?: number;
}

export function SvgTextWrap(props: SvgTextWrapProps) {
    const {
        text,
        x,
        width = 16,
        lineHeight = 16,
    } = props;
    const words = text.split(' ');
    const lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const testLine = `${currentLine} ${words[i]}`;
      const textLength = testLine.length;
      if (textLength < Number(width)) {
        currentLine = testLine;
      } else {
        lines.push(currentLine);
        currentLine = words[i];
      }
    }
    lines.push(currentLine);

    return (
      <text {...props}>
        {lines.map((line, index) => (
          <tspan key={index} x={x} dy={index === 0 ? 0 : lineHeight}>
            {line}
          </tspan>
        ))}
      </text>
    );
}