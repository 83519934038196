import {
    Box,
    BoxProps,
    ListItemButton,
    styled,
    Typography,
    TypographyProps
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ReactComponent as NewConversationIcon } from "./new-conversation.svg";


const Container = styled(Box)<BoxProps>(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "30px",
}));

const Icon = styled(Box)`
    position:relative;
    top: 1px;
    height: 40px;
`

const Text = styled(Typography)<TypographyProps>(({theme}) => ({
  fontWeight: 600,
  fontSize: "14px",
  color: theme.palette.customColors.text.tertiary,
  paddingRight: "8px",
  letterSpacing: "0.4px"
}));

interface ListItemIconButtonProps {
    onClick: () => void;
    children: React.ReactNode;
}

export const ListItemIconButton = (props: ListItemIconButtonProps) => {
    const { onClick, children } = props;
    return (
        <ListItemButton LinkComponent={RouterLink} onClick={onClick}>
          <Container>
            <Text>{children}</Text>
            <Icon><NewConversationIcon /></Icon>
          </Container>
        </ListItemButton>
    );
}