import { CustomColors, DefaultCustomColors } from "../theme";
import * as d3 from 'd3';
import { ChartBody, ChartContainer, ChartHeader } from "./ChartLayout";
import { useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import { GoalMarker } from "./markers/Goal";
import { CardMarker } from "./markers/Card";
import { Tooltip, useTooltip } from "./Tooltip";
import { TeamLogoMarker } from "./markers/TeamLogo";

export default function MatchMomentumChart(props: {
    title: string;
    subTitle?: string;
    width?: number | string;
    height?: number | string;
    colors?: CustomColors;
    data?: any;
}) {
    const { tooltip, showTooltip, hideTooltip } = useTooltip();
    const {
        colors = DefaultCustomColors,
        data = {
            homeTeam: {
                values: [
                   // period 1
                    d3.range(45).reduce((acc, value) => {
                        acc[value] = d3.randomUniform(0, 1)();
                        return acc;
                    }, {} as Record<number, number>),
                    // period 2
                    d3.range(45).reduce((acc, value) => {
                        acc[45+value] = d3.randomUniform(0, 1)();
                        return acc;
                    }, {} as Record<number, number>)
                ],
                goals: [
                    {period: 1, minute: 10}
                ],
                redCards: [
                    {period: 1, minute: 21}
                ]
            },
            awayTeam: {
                values: [
                    // period 1
                    d3.range(45).reduce((acc, value) => {
                        acc[value] = d3.randomUniform(0, 1)();
                        return acc;
                    }, {} as Record<number, number>),
                    // period 2
                    d3.range(45).reduce((acc, value) => {
                        acc[45+value] = d3.randomUniform(0, 1)();
                        return acc;
                    }, {} as Record<number, number>)
                ],
                goals: [
                    {period: 1, minute: 32},
                    {period: 2, minute: 47}
                ]
            }
        }
    } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const width: number = isMobile ? 285 : 768;
    const height: number = isMobile ? 285 : 246;

    const padding = {
        left: 24,
        right: 24
    };

    const paddingBetweenPeriods = isMobile ? 20 : 32;
    const cY = (height) / 2;
    const periodsCount = 2;
    const periodWidth = (width -padding.left - padding.right - (periodsCount-1)*paddingBetweenPeriods) / periodsCount;
    const minValue = -0.0001;
    const maxValue = 1;
    const rW = isMobile ? 2 : 5;

    const s: Record<number, number> = { 1: 0, 2: 45, 3: 90, 4: 105, 5: 120 };
    const e: Record<number, number> = { 1: 45, 2: 90, 3: 105, 4: 120, 5: 135 };

    const yScale = d3.scaleLinear()
        .domain([minValue, maxValue])
        .range([0, cY - 40]);

    const xScale = (minute: number, period: number) => d3.scaleLinear()
        .domain([s[period], Math.max(0, e[period])])
        .range([padding.left + (period-1)*(periodWidth + paddingBetweenPeriods), padding.left + (period-1)*(periodWidth + paddingBetweenPeriods) + periodWidth])(minute);

    const labels = [
        {label: "0`", x: padding.left/2},
        {label: "HT", x: padding.left + periodWidth + paddingBetweenPeriods / 2},
        {label: "FT", x: width - padding.right / 2}
    ]

    return <>
        <ChartContainer {...props}>
            <ChartHeader {...props} />
            <ChartBody colors={colors}>
                <svg viewBox={`0 0 ${width} ${height}`} fontFamily='Poppins'>
                    {/* team logos */}
                    <TeamLogoMarker
                        x={xScale(-1.5, 1)}
                        y={20}
                        bgColor="white"
                    />
                    <TeamLogoMarker
                        x={xScale(-1.5, 1)}
                        y={height - 20}
                        bgColor="white"
                    />
                    {/* values */}
                    {data.homeTeam.values.map((values: any, period: number) => <React.Fragment key={`home-team-period-${period}`}>
                        {Object.entries(values).map(([minute, value]: any, valueIndex: number) => {
                            const w = rW;
                            const x = xScale(minute, period+1);
                            const y = cY - yScale(value);
                            return <g key={`home-team-period-${period}-value-${valueIndex}`}>
                                <rect
                                    x={x - w/2}
                                    y={y}
                                    width={w}
                                    height={yScale(value)}
                                    fill={colors.dataPoints[0]}
                                />
                            </g>
                        })}
                    </React.Fragment>)}
                    {data.awayTeam.values.map((values: any, period: number) => <React.Fragment key={`away-team-period-${period}`}>
                        {Object.entries(values).map(([minute, value]: any, valueIndex: number) => {
                            const w = rW;
                            const x = xScale(minute, period + 1);
                            const height = yScale(value);
                            return <g key={`away-team-period-${period}-value-${valueIndex}`}>
                                <rect
                                    x={x - w/2}
                                    y={cY}
                                    width={w}
                                    height={height}
                                    fill={colors.dataPoints[1]}
                                />
                            </g>
                        })}
                    </React.Fragment>)}
                    {/* axis labels */}
                    {labels.map((label, index) => <g key={`label-${index}`}>
                        <circle
                        cx={label.x}
                        cy={cY}
                        r={isMobile ? 8 : 12}
                        fill={colors.primary.main}
                        />
                        <text
                            x={label.x}
                            y={cY}
                            textAnchor="middle"
                            dy=".35em"
                            fill="white"
                            fontSize={`${isMobile ? 8 : 12}px`}
                        >
                            {label.label}
                        </text>
                    </g>)}
                    {/* goals */}
                    {data.homeTeam.goals.map((goal: any, index: number) => {
                        return <g key={`home-team-goal-${index}`}
                            onMouseMove={(event: any) => {
                                //if (!marker.tooltip) return;
                                const { pageX: x, pageY: y } = event;
                                showTooltip(`Goal: ${goal.minute}'`, {x, y});
                            }}
                            onMouseOut={() => hideTooltip()}
                        >
                            <GoalMarker
                                x={xScale(goal.minute, goal.period)}
                                y={20}
                                color="black"
                                bgColor="white"
                            />
                        </g>
                    })}
                    {data.awayTeam.goals.map((goal: any, index: number) => {
                        return <g key={`away-team-goal-${index}`}
                            onMouseMove={(event: any) => {
                                //if (!marker.tooltip) return;
                                const { pageX: x, pageY: y } = event;
                                showTooltip(`Goal: ${goal.minute}'`, {x, y});
                            }}
                            onMouseOut={() => hideTooltip()}
                        >
                            <GoalMarker
                                x={xScale(goal.minute, goal.period)}
                                y={height - 20}
                                color="black"
                                bgColor="white"
                            />
                        </g>
                    })}
                    {/* red cards */}
                    {data.homeTeam.redCards.map((card: any, index: number) => {
                        return <g key={`home-team-goal-${index}`}
                            onMouseMove={(event: any) => {
                                //if (!marker.tooltip) return;
                                const { pageX: x, pageY: y } = event;
                                showTooltip(`Red Card: ${card.minute}'`, {x, y});
                            }}
                            onMouseOut={() => hideTooltip()}
                        >
                            <CardMarker
                                x={xScale(card.minute, card.period)}
                                y={height - 20}
                                bgColor="white"
                            />
                        </g>
                    })}
                </svg>
            </ChartBody>
        </ChartContainer>
        <Tooltip tooltip={tooltip} />
    </>;
}