import { CircularProgress } from "@mui/material";
import { LayoutContainer } from "../layout";

type Props = {
  variant: "inline" | "full"
};

export default function PageLoader(props: Props) {
  const { variant } = props;
  const sx = variant === 'inline' ? {} : { height: '100vh', alignItems: 'center' };
  return (
    <LayoutContainer contentAlign="center" sx={sx}>
      <CircularProgress size="small" />
    </LayoutContainer>
  );
}
