import { styled } from "@mui/material";
import { BaseChartProps, ChartBody, ChartContainer, ChartFooter, ChartHeader, StyledBodyText } from "./ChartLayout";
import { useTheme } from '@mui/material/styles';
import { Tooltip, useTooltip } from './Tooltip';
import { SvgTextWrap } from "./common/SvgTextWrap";

interface RadarChartProps extends BaseChartProps {
    title: string;
    subTitle?: string;
    bottomTitle?: string;
    axies: Array<string>;
    series: Array<{
        data: Array<number>;
        name?: string;
        color?: string,
        hover_strings: Array<string>
    }>;
    width?: number;
    height?: number;
    value_range?: [number, number];
    levelNumber?: number;
    playerName?: string;
};

const StyledLabel = styled(SvgTextWrap)(({ theme }) => ({
    stroke: "none",
    shapeRendering: "crispEdges",
    fontFamily: theme.typography.fontFamily,
    fill: theme.palette.customColors.text.primary,
    fontWeight: 200,
    fontSize: '16px',
}));

const StyledFooterText = styled("div")(({ theme }) => ({
    color: theme.palette.customColors.text.primary,
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
        fontSize: '12px',
    }
  }));
  
const StyledFooterBox = styled("div")(({ theme }) => ({
    color: theme.palette.customColors.text.primary,
    gap: '8px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
    }
}));

export default function RadarChart(props: RadarChartProps) {
    const theme = useTheme();
    const { customColors } = theme.palette;
    const {
        bottomTitle,
        axies,
        series,
        width = 496,
        height = 360,
        value_range = [0, 100],
        levelNumber = 5,
        colors,
        size,
    } = props;
    const { tooltip, showTooltip, hideTooltip } = useTooltip();

    const gridFillColor = "rgb(0, 56, 33)";
    const gridStrokeColor = "rgba(0, 153, 64, 0.2)";
    const radius = 130;
    const minValue = value_range[0];
    const maxValue = value_range[1];
    const angleSlice = Math.PI * 2 / axies.length;
    const xScale = (value: number, index: number) => {
        return ((value - minValue)* radius/(maxValue - minValue)) * Math.cos(angleSlice*index - Math.PI/2);
    }
    const yScale = (value: number, index: number) => {
        return ((value - minValue)* radius/(maxValue - minValue)) * Math.sin(angleSlice*index - Math.PI/2);
    }
    const textAnchor = (index: number) => {
        if (index === 0 || angleSlice * index === Math.PI) return "middle";
        return (angleSlice * index < Math.PI) ? "start" : "end";
    }

    return (<>
        <ChartContainer {...props}>
            <ChartHeader {...props} />
            <ChartBody colors={colors} size={size} sx={{padding: '0 8px'}}>
                <svg
                    style={{width: '100%', height: '100%'}}
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}>
                    <g transform={`translate(${width/2},${height/2})`}>
                        <g className="axis-wrapper">
                            {Array.from({length: levelNumber}, (level, index) => levelNumber - index).map((level, index) =>
                                <circle
                                    key={`level-${index}`}
                                    className="axis-grid-circle"
                                    r={radius/levelNumber*level}
                                    fill={gridFillColor}
                                    stroke={gridStrokeColor}>
                                </circle>
                            )}
                            {axies.map((axis, index) =>
                                <g key={`axis-${index}`}>
                                    <line
                                        x1={0}
                                        y1={0}
                                        x2={xScale(maxValue, index)}
                                        y2={yScale(maxValue, index)}
                                        stroke={gridStrokeColor}>
                                    </line>
                                    <text></text>
                                    <StyledLabel
                                        text={axis}
                                        dy="0.35em"
                                        fontSize="18px"
                                        fill={customColors.text.primary}
                                        x={xScale(maxValue * 1.12, index)}
                                        y={yScale(maxValue * 1.12, index)}
                                        textAnchor={textAnchor(index)}
                                        width={index === 0 || angleSlice*index === Math.PI ? 36 : 14}
                                    />
                                </g>
                            )}
                            {series.map((serie, serieIndex) =>
                                <g key={`serie-${serieIndex}`}>
                                    <path
                                        stroke={serie.color}
                                        strokeWidth={2}
                                        style={{fill: serie.color, fillOpacity: 0.35}}
                                        d={`M${serie.data.map((value, index) => `${xScale(value, index)} ${yScale(value, index)}`)}Z`}>
                                    </path>
                                    {
                                        serie.data.map((value, dataIndex) => (
                                            <circle
                                                key={`serie-${serieIndex}-data-${dataIndex}`}
                                                cx={xScale(Math.min(maxValue, value), dataIndex)}
                                                cy={yScale(Math.min(maxValue, value), dataIndex)}
                                                r={5}
                                                fill={'transparent'}
                                                stroke={'transparent'}
                                                onMouseMove={(event: any) => {
                                                    const { pageX: x, pageY: y } = event;
                                                    showTooltip(serie.hover_strings[dataIndex], {x, y});
                                                }}
                                                onMouseOut={() => hideTooltip()}
                                            >
                                            </circle>
                                        ))
                                    }
                                </g>
                            )}
                        </g>
                    </g>
                </svg>
                {bottomTitle ? <StyledBodyText colors={colors} size={size}><>{bottomTitle}</></StyledBodyText> : <></>}
            </ChartBody>
            <ChartFooter colors={colors} size={size}>
                <StyledFooterBox sx={{ gap: '16px'}}>
                    {series.map((serie, index) =>
                        <StyledFooterBox key={`legend-${index}`}>
                            <div
                                style={{
                                    width: '32px',
                                    height: '3px',
                                    backgroundColor: serie.color
                                }}
                            />
                            <StyledFooterText>{serie.name}</StyledFooterText>
                        </StyledFooterBox>
                    )}
                </StyledFooterBox>
            </ChartFooter>
        </ChartContainer>
        <Tooltip tooltip={tooltip} />
    </>);
}
