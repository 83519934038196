import React, { useState } from 'react';
import { css, styled, IconButton } from "@mui/material";
import { LayoutContainer } from "../layout";
import { MessageModel, StreamedResponseChunk } from "../../api/twelve";
import { ReactComponent as SvgLogo } from "../elements/logo-circle-transparent.svg";
import { Chart } from "../charts/Chart";
import SwipeableContent from "../common/SwipeableContent";
import MarkdownText from "../common/MarkdownText";
import { CopyAllRounded, ThumbDownAltOutlined, CheckRounded, ThumbDownAlt } from "@mui/icons-material";
import { CustomColors, DefaultCustomColors } from '../theme';
import ChartHelper from '../../helpers/chart.helper';

export type MessageTextVariant = 'system' | 'user' | 'pending' | 'assistant';

interface MessageTextProps {
  variant?: MessageTextVariant
}

interface ToolbarProps {
  permanent: boolean | null
}

const MessageText = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<MessageTextProps>(({ variant, theme }) => (css`
  display: inline-block;
  max-width: 100%;
  border-radius: 24px;
  padding: ${variant === 'user' ? '0 1rem' : 0};
  color: 'auto';
  border: ${variant === 'pending' ? '1px solid #444' : 'none'};
  background-color: ${ variant === 'user' ? theme.palette.customColors.background.secondary : variant === 'pending' ? '#fff' : 'transparent'};
  ${variant === 'assistant' && css`
    p:first-of-type {
      margin-top: 0px;
    }
    p:last-of-type {
      margin-bottom: 0px;
    }`
  }
`));

const Logo = styled(SvgLogo, {
  shouldForwardProp: (prop) => prop !== 'height',
})(({ theme }) => ({
  '&': css`
    width: 24px;
    min-width: 24px;
    height: 24px;
  `
}));

const ToolbarContainer = styled('div')`
  height:30px;
`

const Toolbar = styled('div', {
  shouldForwardProp: (prop) => prop !== 'permanent',
})<ToolbarProps>(({ permanent }) => (css`
  position:relative;
  left: -19px;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 3px;
  max-width: 85px;
  visibility: ${permanent ? 'visible' : 'hidden'};
  transform: scale(0.8);
`))

const StyledLayoutContainer = styled(LayoutContainer, {
  shouldForwardProp: (prop) => prop !== 'permanent',
})<ToolbarProps>(({ permanent }) => (css`
  &:hover .toolbar {
    visibility: visible;
    border: 1px solid ${permanent ? '#fff' : '#dedede'};
  }
`))

export default function MessageTextItem({ messageId, content, variant, permaToolbar, disliked, onUpdateDisliked }: { 
    messageId?: number; 
    content: string; 
    variant: MessageTextVariant; 
    permaToolbar: boolean; 
    disliked?: boolean;
    onUpdateDisliked?: (messageId: number, disliked: boolean) => void 
  }) {
  const [copied, setCopied] = useState(false);
  const [hasDisliked, setHasDisliked] = useState(disliked);

  const handleCopy = () => {
    navigator.clipboard.writeText(content)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  const handleUpdateDislike = () => {
    if (onUpdateDisliked && messageId) {
      onUpdateDisliked(messageId, !hasDisliked);
      setHasDisliked(!hasDisliked);
    }
  };

  return (
    <StyledLayoutContainer contentAlign={variant === 'user' ? 'end' : 'start' } sx={{gap: '1rem'}} permanent={permaToolbar}>
      {variant !== 'user' && <Logo />}
      <MessageText variant={variant}>
        <MarkdownText text={content}></MarkdownText>
        {variant === 'assistant' && (
          <ToolbarContainer>
            <Toolbar permanent={permaToolbar} className="toolbar">
              <IconButton onClick={handleUpdateDislike}>
                {hasDisliked ? <ThumbDownAlt fontSize="small" /> : <ThumbDownAltOutlined fontSize="small" />}
              </IconButton>
              <IconButton onClick={handleCopy}>
                {copied ? <CheckRounded fontSize="small" /> : <CopyAllRounded fontSize="small" />}
              </IconButton>
            </Toolbar>
          </ToolbarContainer>
        )}
      </MessageText>
    </StyledLayoutContainer>
  );
}

export function MessagePlotsItem({ plots, chatId }: { plots: Array<any>, chatId: number; }) {
  const colors = JSON.parse(JSON.stringify(DefaultCustomColors)) as CustomColors;
  colors.title.primary = 'rgba(0, 0, 0, 0.87)';
  colors.title.secondary = 'rgba(0, 0, 0, 0.60)';
  return (
    <LayoutContainer contentAlign={'start'} sx={{gap: '1rem'}}>
      <Logo />
      <SwipeableContent>
        {plots!.sort((a,b) =>
          ChartHelper.ORDER[ChartHelper.GetChartVariantFromUrl(a.url)] - ChartHelper.ORDER[ChartHelper.GetChartVariantFromUrl(b.url)])
          .map((p: any, i: number) =>
          <Chart
            url={p.url}
            key={`chart _${i}`}
            variant={ChartHelper.GetChartVariantFromUrl(p.url)}
            json_body={p.json_body}
            size={'sm'}
            colors={colors}
          />
        )}
      </SwipeableContent>
    </LayoutContainer>
  );
}

type MessageProps = {
  chatId: number,
  message: MessageModel | StreamedResponseChunk
  permaToolbar: boolean
  onUpdateDisliked?: (messageId: number, disliked: boolean) => void
}

export function MessageItem(props: MessageProps) {
  const { message, chatId, permaToolbar, onUpdateDisliked } = props;
  const messageId = (message as MessageModel).id ?? null;
  const messageDisliked = (message as MessageModel).disliked ?? false;
  const content = (message as MessageModel).content ?? (message as StreamedResponseChunk).content_chunk;

  return message.plots ? (
    <MessagePlotsItem plots={message.plots} chatId={chatId} />
  ) : (
    <MessageTextItem messageId={messageId} content={content} variant={message.role as MessageTextVariant} permaToolbar={permaToolbar} onUpdateDisliked={onUpdateDisliked} disliked={messageDisliked} />
  );
}