import React, { useEffect, useState } from "react";
import { ChartModel, api } from "../../api/twelve";
//import { PlotParams } from "react-plotly.js";
import RadarChart from "./RadarChart";
import DistributionChart from "./DistributionChart";
//import ScatterChart from "./ScatterChart";
//import { GoalMarker } from "./markers/Goal";
//import { CardMarker } from "./markers/Card";
import { CustomColors, DefaultCustomColors, theme } from "../theme";
import { FullPitchChart } from "./FullPitchChart";
import { ThirdsPitchChart } from "./ThirdsPitchChart";
import { ContainerProps, Typography } from "@mui/material";
import StringHelper from "../../helpers/string.helper";
import { ChartType } from "../../models";

interface Props extends ContainerProps {
  variant: ChartType;
  url?: string;
  json_body?: Record<string, any>;
  colors?: CustomColors;
  size?: 'lg' | 'sm';
};

export function Chart(props: Props) {
  const {
    variant = 'distribution',
    url,
    json_body = {},
    colors = DefaultCustomColors,
    size = 'lg',
  } = props;

  const [chart, setChart] = useState<ChartModel | undefined>();

  useEffect(() => {
    const fn = async () => {
      if (!url) return;

      const chartData = await api.getChart(url, json_body, "json") as ChartModel;
      setChart(chartData);
    };
    fn();
  }, [json_body, url]);

  const renderChart = () => {
    if (!chart) return <></>;

    switch (variant) {
      case 'radar':
        return <RadarChart
          sx={props.sx}
          colors={colors}
          size={size}
          title={chart.title}
          subTitle={chart.subtitle}
          bottomTitle={`Compared to other ${StringHelper.normalizeKey(chart.player_info?.position)}s`}
          value_range={chart.data.value_range}
          axies={chart.data.kpi_names}
          series={chart.data.plot_values.map((data: Array<number>, index: number) => {
            const colorIndex = chart.data.plot_values.length > 1 ? index : 1;
            return {
              name: chart.data.target_labels[index],
              color: colors.dataPoints[colorIndex],
              hover_strings: chart.data.hover_strings[index]?.map(s => s.replaceAll('\n', '<br/>')),
              data,
            }
          })}
        />;
      case 'distribution':
        return <DistributionChart
          {...props}
          colors={colors}
          size={size}
          title={chart.title}
          subTitle={chart.subtitle}
          range={chart.data.value_range}
          metrics={chart.data.kpis}
          series={[{
            name: `Other ${StringHelper.normalizeKey(chart.player_info?.position)}s`,
            values: chart.data.plot_values.filter((_,i) => i !== chart.data.focal_target_indices[0]),
            hoverStrings: chart.data.hover_strings.filter((_,i) => i !== chart.data.focal_target_indices[0]),
            marker: {
              variant: 'line',
              color: colors.dataPoints[0],
            }
          }, {
            name: chart.player_info?.name,
            values: [chart.data.plot_values[chart.data.focal_target_indices[0]]],
            hoverStrings: [chart.data.hover_strings[chart.data.focal_target_indices[0]]],
            marker: {
              variant: 'circle',
              color: colors.dataPoints[1],
            }
          }
        ]}
        />;
      // case 'line': {
      //   const x = [0, 90/5, 2*90/5, 3*90/5, 4*90/5, 90];
      //   return <ScatterChart
      //     colors={colors}
      //     title={'Line chart'}
      //     subTitle="Description"
      //     series={chart.data.map((d: any) => ({
      //       x: x,
      //       y: d.y.slice(0,6),
      //       name: d.name,
      //     })).slice(0,1)}
      //     xDomain={[0, 90]}
      //     xAxis={{
      //       labels: [
      //         {title: "0-15", x: x[0]},
      //         {title: "15-30", x: x[1]},
      //         {title: "30-HT", x: x[2]},
      //         {title: "45-60", x: x[3]},
      //         {title: "60-75", x: x[4]},
      //         {title: "90-FT", x: x[5]}
      //       ],
      //       markers:[{
      //         position: "top",
      //           x: 10,
      //           marker: GoalMarker,
      //           tooltip: "Goal<br/>Cristiano Ronaldo<br/>10'"
      //         }, {
      //           position: "top",
      //           x: 26,
      //           marker: CardMarker,
      //           tooltip: "Red Card<br/>Cristiano Ronaldo<br/>26'"
      //         }, {
      //           position: "bottom",
      //           x: 50,
      //           marker: GoalMarker,
      //           tooltip: "Goal<br/>M.Fenger<br/>50'"
      //         }, {
      //           position: "bottom",
      //           x: 90,
      //           marker: GoalMarker,
      //           tooltip: "Goal<br/>M.Fenger<br/>90'"
      //         }]
      //     }}
      //     unit="%"
      //   />;
      // }
      case 'pitch':
      case 'fullpitch': {
        return <FullPitchChart
          {...props}
          title={chart.title}
          subTitle={chart.subtitle}
          colors={colors}
          labels={["Defensive", "Offensive"]}
          footerText={`Compared to other ${StringHelper.normalizeKey(chart.player_info?.position)}s`}
          data={chart.data.zones}
      />
      }
      case 'thirdspitch': {
        const thirdsPitchData = {
          points: Array.from(Array(Math.round(100*Math.random())).keys()).map((_, index) => ({
              x: 5 + 90*Math.random(),
              y: 5 + 90*Math.random(),
              value: 100*Math.random()
          })),
          rating: Array.from(Array(Math.round(3)).keys()).map(() => Math.random())
        };

        return  <ThirdsPitchChart
          {...props}
          title="Where did Manchester United recover the Ball?"
          subTitle="Manchester United  2 - 0 Everton | 21/3-2024"
          labels={ ["Defensive", "Mid", "Offensive"] }
          footerText="Compared to other *Postion*"
          data={thirdsPitchData}
        />
      }
      default:
        console.log(variant)
        return <Typography variant="body1" sx={{color: theme.palette.error.main}}>Not supported variant</Typography>;
    }
  };

  return <>
    {renderChart()}
  </>;
}
