import MarkerProps from "./MarkerProps";

export function CardMarker(props: MarkerProps) {
    const {
        color = "#FF0000",
        bgColor = 'transparent',
        x = 0,
        y = 0
    } = props;
    return <svg width={20} height={20} viewBox="0 0 20 20" x={x-10} y={y-10}>
        <circle cx={10} cy={10} r={10} fill={bgColor} />
        <svg x={3.5} y={4} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2.25" width="8.25" height="12" rx="2" fill={color}/>
        </svg>
    </svg>;
}