import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ViewState {
  sidebar: {
    opened: boolean;
  },
  appModalMenu: {
    opened: boolean;
  },
}

const initialState: ViewState = {
  sidebar: {
    opened: true,
  },
  appModalMenu: {
    opened: false,
  }
};

const sidebarSlice = createSlice({
  name: 'view',
  initialState,
  reducers: {
    toggleSidebar(state, action: PayloadAction<boolean | undefined>) {
      state.sidebar.opened = action.payload === undefined ? !state.sidebar.opened : action.payload;
    },
    toggleAppModalMenu(state, action: PayloadAction<boolean | undefined>) {
      state.appModalMenu.opened = action.payload === undefined ? !state.appModalMenu.opened : action.payload;
    },
  },
});

export const {
  toggleSidebar,
  toggleAppModalMenu,
} = sidebarSlice.actions;

export default sidebarSlice.reducer;
