const sizes = {
  'small': 92,
  'large': 154
};

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: 'small' | 'large';
};

export default function ReportLogo(props: Props) {
  const {
    size = 'small',
  } = props;

  return <svg
    style={{
      width: `${sizes[size]}px`, height: 'auto' }}
      width="92"
      height="24"
      viewBox="0 0 92 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
    <g>
      <path d="M5.93475 12.9108H9.15907L8.30865 9.82318H5.9393V5.81104L2.39209 6.88124V9.82318H0V12.9108H2.39209V18.9587C2.39209 22.6748 4.07019 24.1321 9.04083 23.5811V20.3613C7.00801 20.4706 5.93475 20.4433 5.93475 18.9587V12.9108Z" fill="#009940"/>
      <path d="M45.1996 16.6998C45.1996 12.6876 41.9525 9.43604 37.946 9.43604C33.9394 9.43604 30.6924 12.6876 30.6924 16.6998C30.6924 20.7119 33.9394 23.9636 37.946 23.9636C41.0748 23.9636 43.7443 21.978 44.7584 19.2H41.1748C40.429 20.1654 39.2603 20.7894 37.946 20.7894C35.6903 20.7894 33.8621 18.9586 33.8621 16.6998C33.8621 14.441 35.6903 12.6102 37.946 12.6102C39.7514 12.6102 41.2794 13.7852 41.8206 15.411H34.8808V15.4201L35.5675 17.9112H45.0995C45.1677 17.515 45.1996 17.1142 45.1996 16.6998Z" fill="#009940"/>
      <path d="M24.7759 16.4221L26.454 22.1238L30.3605 9.82324H26.5313L24.7759 16.4221Z" fill="#009940"/>
      <path d="M9.74121 9.82324L13.916 23.5811H17.3632L13.5704 9.82324H9.74121Z" fill="#009940"/>
      <path d="M18.4047 9.82324L16.4583 16.4039L18.0181 22.0555L20.0828 15.2426L22.6113 23.5811H25.8038L21.7609 9.82324H18.4047Z" fill="#009940"/>
      <path d="M52.4851 9.82324L57.7059 23.5811H61.1348L56.387 9.82324H52.4851Z" fill="#009940"/>
      <path d="M60.2571 17.8749L61.9989 22.9117L66.9649 9.82324H63.063L60.2571 17.8749Z" fill="#009940"/>
      <path d="M51.1115 4.13965H47.5688V23.581H51.1115V4.13965Z" fill="#009940"/>
      <path d="M81.6676 16.6998C81.6676 12.6876 78.4205 9.43604 74.414 9.43604C70.4075 9.43604 67.1604 12.6876 67.1604 16.6998C67.1604 20.7119 70.4075 23.9636 74.414 23.9636C77.5428 23.9636 80.2123 21.978 81.2264 19.2H77.6429C76.897 20.1654 75.7283 20.7894 74.414 20.7894C72.1583 20.7894 70.3301 18.9586 70.3301 16.6998C70.3301 14.441 72.1583 12.6102 74.414 12.6102C76.2194 12.6102 77.7474 13.7852 78.2886 15.411H71.3488V15.4201L72.0355 17.9112H81.5675C81.6357 17.515 81.6676 17.1142 81.6676 16.6998Z" fill="#009940"/>
      <path d="M83.9052 0.191162H81.936V9.82304H83.9052V0.191162Z" fill="#FF4B00"/>
      <path d="M91.7589 3.02846C91.7589 1.10209 90.1763 0 88.4664 0C87.1111 0 85.8151 0.660342 85.1738 2.03567L86.8292 2.96926C87.1566 2.28159 87.7251 1.83985 88.4982 1.83985C89.2531 1.83985 89.7943 2.32258 89.7943 3.09222C89.7943 3.7389 89.294 4.37192 88.6528 5.0186L85.2466 8.40228V9.8186H86.2698L90.1217 6.10247C91.0631 5.20076 91.7589 4.19431 91.7589 3.02846Z" fill="#FF4B00"/>
      <path d="M91.9817 7.99243H89.3304L87.4294 9.82317H91.9817V7.99243Z" fill="#FF4B00"/>
      <path d="M81.1265 0.191162H79.3438V1.89894H81.1265V0.191162Z" fill="#FF4B00"/>
    </g>
  </svg>;
}