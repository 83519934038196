import { Typography, Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface MarkdownRendererProps {
  text?: string;
}

export default function MarkdownText({ text = '' }: MarkdownRendererProps) {
  return (
    <Typography component={'span'} variant={'body1'}>
      <ReactMarkdown
        children={text}
        remarkPlugins={[remarkGfm]}
        components={{
          code({ inline, children, ...props }: { inline?: boolean; children?: React.ReactNode }) {
            return inline ? (
              <Typography
                component="code"
                sx={{
                  backgroundColor: '#f6f6f6',
                  borderRadius: '4px',
                  padding: '2px 4px',
                  fontSize: '10px',
                  fontFamily: 'monospace',
                }}
                {...props}
              >
                {children}
              </Typography>
            ) : (
              <Box
                component="pre"
                sx={{
                  backgroundColor: '#f6f6f6',
                  padding: '12px',
                  borderRadius: '5px',
                  fontSize: '10px',
                  overflow: 'auto',
                  width: 'calc(100% - 30px)',
                }}
                {...props}
              >
                <code>{children}</code>
              </Box>
            );
          },
        }}
      />
    </Typography>
  );
}
