import { Box } from "@mui/material";
import {
    TitlePage as TitlePageContainer,
    Title,
    SubTitle,
} from "../ReportLayout";
import ReportLogo from "../ReportLogo";
import { ReportPage } from "../../../models";

export default function TitlePage({page}: {page: ReportPage}) {
    return <TitlePageContainer>
        <Box sx={{position: 'absolute', top: '32px', right: '32px' }}>
            <ReportLogo />
        </Box>
        <Box sx={{
            width: '100%',
            position: 'absolute',
            left: "50%",
            top: '35%',
            transform: 'translateX(-50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '12px'
        }}>
            <Title variant='caption'>{page?.title}</Title>
            <SubTitle variant='caption'>{page?.subtitle}</SubTitle>
            <SubTitle variant='caption'>{page?.subsubtitle}</SubTitle>
            <SubTitle variant='caption' sx={{color: "rgba(255,255,255,0.5)"}}>{page?.subsubsubtitle}</SubTitle>
        </Box>
    </TitlePageContainer>
}