import { styled, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

interface TypingEffectProps {
  messages: string[];
  typingSpeed?: number;
  clearingSpeed?: number;
  pauseTime?: number;
}

const BlinkingCursor = styled('span')(({ theme }) => ({
  marginLeft: '2px',
  animation: 'blink 1s step-end infinite',
  '@keyframes blink': {
    '50%': { opacity: 0 },
  },
}));

const TypingEffect: React.FC<TypingEffectProps> = ({
  messages,
  typingSpeed = 40,
  clearingSpeed = 25,
  pauseTime = 2000,
}) => {
  const [displayedText, setDisplayedText] = useState<string>('');
  const [isTyping, setIsTyping] = useState<boolean>(true);
  const [messageIndex, setMessageIndex] = useState<number>(0);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const currentText = messages[messageIndex];

    if (isTyping && displayedText.length < currentText.length) {
      timeoutId = setTimeout(() => {
        setDisplayedText((prev) => currentText.slice(0, prev.length + 1));
      }, typingSpeed);
    }
    else if (isTyping && displayedText.length === currentText.length) {
      timeoutId = setTimeout(() => {
        setIsTyping(false);
      }, pauseTime);
    }
    else if (!isTyping && displayedText.length > 0) {
      timeoutId = setTimeout(() => {
        setDisplayedText((prev) => prev.slice(0, prev.length - 1));
      }, clearingSpeed);
    }
    else if (!isTyping && displayedText.length === 0) {
      timeoutId = setTimeout(() => {
        setIsTyping(true);
        setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
      }, 1500);
    }

    return () => clearTimeout(timeoutId);
  }, [displayedText, isTyping, messageIndex, messages, typingSpeed, clearingSpeed, pauseTime]);

  return <Typography
    variant="h5"
    sx={{
      fontSize: '34px'
    }}
  >
    {displayedText}
    <BlinkingCursor>|</BlinkingCursor>
  </Typography>
};

export default TypingEffect;
