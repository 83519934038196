import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '.';
import { Report } from '../models';
import { api } from '../api/twelve';

interface SavedReportsState {
  items: Array<Report>;
  loading: boolean;
  error: string | null;
}

const initialState: SavedReportsState = {
  items: [],
  loading: false,
  error: null,
};

const reportsSlice = createSlice({
  name: 'savedReports',
  initialState,
  reducers: {
    fetchItemsStart: (state) => {
      state.loading = true;
    },
    fetchItemsSuccess: (state, action: PayloadAction<{items: Array<Report>}>) => {
      state.items = action.payload.items;
      state.loading = false;
    },
    failure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    addItem: (state, action: PayloadAction<Report>) => {
      state.items.unshift(action.payload);
      state.items.slice(0, 5);
    },
  },
});

export default reportsSlice.reducer;

// Thunk actions for API calls
export const fetchSavedReports: any = (
  token: string,
) => async (dispatch: AppDispatch) => {
  try {
    dispatch(reportsSlice.actions.fetchItemsStart());
    const response = await api.getSavedReports(token, 1, 5);
    dispatch(reportsSlice.actions.fetchItemsSuccess({items: response}));
  } catch (error: any) {
    console.error('Failed to fetch saved reports:', error);
    dispatch(reportsSlice.actions.failure(error.message));
  }
};
