import React, {
    useEffect,
} from "react";
import {
    List,
    ListItemButton,
    ListItemText,
    ListSubheader,
    Typography
} from "@mui/material";
import {
    Link as RouterLink,
    useParams,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { CustomRouterLink } from "../common/CustomRouterLink";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { RootState } from "../../store";
import { fetchSavedReports } from "../../store/savedReportsReducer";

export function SavedReportList() {
    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const {items } = useSelector((state: RootState) => state.savedReports);
    const { reportId } = useParams<{ reportId: string }>();

    useEffect(() => {
        const getReports = async () => {
          dispatch(fetchSavedReports(await getAccessTokenSilently()));
        };

        getReports();
    }, [getAccessTokenSilently, dispatch]);

    return <>
        {items.length
        ? <>
            <ListSubheader>Reports</ListSubheader>
            <List 
                dense
                sx={{
                    padding: 0,
                    maxHeight: '186px',
                    overflow: 'auto',
                    flexShrink: 0
                }}
            >
                {items.map((report) => (
                    <ListItemButton
                        key={`report-${report.id}`}
                        component={CustomRouterLink}
                        to={{
                            pathname: `/report/${report.id}`,
                            state: { report }
                        }}
                        selected={report.id === Number(reportId)}
                    >
                        <ListItemText>
                            <Typography sx={{
                                fontWeight: report.id === Number(reportId) ? '600' : '200',
                                paddingLeft: '12px',
                                fontSize: '14px'
                            }}>
                                {report.name}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                ))}
            </List>
        </>
        : <></>
        }
        <ListSubheader sx={{p:0}}>
            <ListItemButton
                component={RouterLink}
                to={`/reports`}
            >
                <ListItemText>
                    <Typography
                        sx={{
                            fontWeight: '600',
                            fontSize: '13px',
                            textAlign: 'center',
                            color: (theme) => theme.palette.customColors.text.tertiary
                        }}
                    >
                        All Reports
                    </Typography>
                </ListItemText>
            </ListItemButton>
        </ListSubheader>
  </>
}