import { ChartType } from "../models";

export default class ChartHelper {
    public static ORDER: Record<ChartType, number> = {
        'radar': 0,
        'distribution': 1,
        'fullpitch': 2,
        'pitch': 2,
        'thirdspitch': 2,
        'line': 3,
    };

    public static GetChartVariantFromUrl(url: string | undefined): ChartType {
        return url?.split('/').pop() as ChartType;
    }
}