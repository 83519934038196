import { configureStore } from '@reduxjs/toolkit';
import viewReducer from './viewReducer';
import conversationsReducer from './conversationsReducer';
import reportsReducer from './reportsReducer';
import savedReportsReducer from './savedReportsReducer';

const store = configureStore({
  reducer: {
    view: viewReducer,
    conversations: conversationsReducer,
    reports: reportsReducer,
    savedReports: savedReportsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
