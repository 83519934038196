const base = {
    conversationNaming: {
        default: "New chat",
        userMessageThreshold: 3,
    }
}

const dev = {
    auth0: {
        domain: "dev-uhb1vhxl5dni3uog.eu.auth0.com",
        clientId: "j4PLwYFQ2D0myyeeFe3xE0XNYxZpjwl1",
        audience: "https://gpt-v2-api-dev.twelve.football"
    },
    guest_token: "07452e4c-ad48-4810-a755-d4eab95d6357", // not sure if it is correct place to store guest token
}

export const config = {
    ...base,
    ...dev
}
