import {
  Box,
  BoxProps,
  useTheme,
  styled,
  Pagination,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
//import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExpandableText from './ExpandableText';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface SwipeableContentProps {
  descriptions?: Array<string | undefined>;
  children: React.ReactNode;
  indicatorsVariant?: 'circle' | 'button';
  indicatorsStyle?: {
    borderColor: string;
  }
};

const IndicatorsBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '6px',
  height: '24px',
}));

const SwipeableContent: React.FC<SwipeableContentProps> = ({
  children,
  descriptions,
  indicatorsVariant = 'circle',
  indicatorsStyle,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
    trackMouse: true,
  });

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, childrenCount - 1));
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleJump = (index: number) => {
    setCurrentIndex(index);
  };

  const childrenCount = React.Children.count(children);

  return (
    <Box id="swiperable" sx={{
      width: '100%',
      userSelect: 'none',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Box
        {...handlers}
        sx={{
          width: '100%',
          height: '100%',
          overflowX: 'hidden',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          {React.Children.map(children, (child, index) => (
            <Box
              key={index}
              sx={{
                left: `${(index - currentIndex) * 100}%`,
                position: `${index === currentIndex ? 'relative' : 'absolute'}`,
                top: 0,
                height: '100%',
                width: '100%',
                transition: 'left 0.3s ease-in-out',
                overflow: 'hidden'
              }}
            >
              {child}
            </Box>
          ))}
      </Box>
      {childrenCount > 1 && (
        indicatorsVariant === 'circle'
        ? <IndicatorsBox sx={{height: '34px'}}>
            {!isMobile
              ? <IconButton
                size='small'
                disabled={currentIndex === 0}
                onClick={() => handleJump(currentIndex - 1)}
                sx={{color: theme.palette.primary.dark}}
              >
                <KeyboardArrowLeftIcon sx={{height: '18px', width: '18px'}} />
              </IconButton>
              : <></>
            }
            {React.Children.map(children, (_, index) => (
              <Box
                key={`indicator-${index}`}
                onClick={() => handleJump(index)}
                sx={{
                  cursor: 'pointer',
                  border: `2px solid ${indicatorsStyle?.borderColor || theme.palette.primary.dark}`,
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  background: currentIndex === index ? (indicatorsStyle?.borderColor || theme.palette.primary.dark) : 'transparent' }}
              />
            ))}
            {!isMobile
            ? <IconButton
              size='small'
              disabled={currentIndex === childrenCount - 1}
              onClick={() => handleJump(currentIndex + 1)}
              sx={{color: theme.palette.primary.dark}}
            >
              <KeyboardArrowRightIcon sx={{height: '18px', width: '18px'}} />
            </IconButton>
            : <></>
          }
        </IndicatorsBox>
        : <IndicatorsBox sx={{height: '80px'}}>
          <Pagination
            count={childrenCount}
            page={currentIndex+1}
            onChange={(event, value) => setCurrentIndex(value-1)}
            sx={{
              '& .MuiPaginationItem-root': {
                color: theme.palette.customColors.text.secondary,
              },
              '& .MuiPaginationItem-page.Mui-selected': {
                color: theme.palette.customColors.text.primary,
                background: theme.palette.customColors.background.tertiary,
              },
            }}
          />
        </IndicatorsBox>
      )}
      {descriptions &&
        <Box sx={{
            color: theme.palette.customColors.text.primary,
            fontSize: '16px',
            marginTop: '16px',
            width: '100%',
        }}>
            {/* <InfoOutlinedIcon/> */}
            <ExpandableText text={descriptions[currentIndex]} />
        </Box>
      }
    </Box>
  );
};

export default SwipeableContent;