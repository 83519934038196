import MarkerProps from "./MarkerProps";

export function GoalMarker(props: MarkerProps) {
    const {
        color = "#002C1C",
        bgColor = 'transparent',
        x = 0,
        y = 0
    } = props;
    return <svg width={20} height={20} viewBox="0 0 20 20" x={x-10} y={y-10}>
        <circle cx={10} cy={10} r={10} fill={bgColor} />
        <svg x={3.5} y={4} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 11C5.80833 11 5.15833 10.8688 4.55 10.6062C3.94167 10.3438 3.4125 9.9875 2.9625 9.5375C2.5125 9.0875 2.15625 8.55833 1.89375 7.95C1.63125 7.34167 1.5 6.69167 1.5 6C1.5 5.30833 1.63125 4.65833 1.89375 4.05C2.15625 3.44167 2.5125 2.9125 2.9625 2.4625C3.4125 2.0125 3.94167 1.65625 4.55 1.39375C5.15833 1.13125 5.80833 1 6.5 1C7.19167 1 7.84167 1.13125 8.45 1.39375C9.05833 1.65625 9.5875 2.0125 10.0375 2.4625C10.4875 2.9125 10.8438 3.44167 11.1062 4.05C11.3688 4.65833 11.5 5.30833 11.5 6C11.5 6.69167 11.3688 7.34167 11.1062 7.95C10.8438 8.55833 10.4875 9.0875 10.0375 9.5375C9.5875 9.9875 9.05833 10.3438 8.45 10.6062C7.84167 10.8688 7.19167 11 6.5 11ZM9 4.75L9.675 4.525L9.875 3.85C9.60833 3.45 9.2875 3.10625 8.9125 2.81875C8.5375 2.53125 8.125 2.31667 7.675 2.175L7 2.65V3.35L9 4.75ZM4 4.75L6 3.35V2.65L5.325 2.175C4.875 2.31667 4.4625 2.53125 4.0875 2.81875C3.7125 3.10625 3.39167 3.45 3.125 3.85L3.325 4.525L4 4.75ZM3.475 8.6L4.05 8.55L4.425 7.875L3.7 5.7L3 5.45L2.5 5.825C2.5 6.36667 2.575 6.86042 2.725 7.30625C2.875 7.75208 3.125 8.18333 3.475 8.6ZM6.5 10C6.71667 10 6.92917 9.98333 7.1375 9.95C7.34583 9.91667 7.55 9.86667 7.75 9.8L8.1 9.05L7.775 8.5H5.225L4.9 9.05L5.25 9.8C5.45 9.86667 5.65417 9.91667 5.8625 9.95C6.07083 9.98333 6.28333 10 6.5 10ZM5.375 7.5H7.625L8.325 5.5L6.5 4.225L4.7 5.5L5.375 7.5ZM9.525 8.6C9.875 8.18333 10.125 7.75208 10.275 7.30625C10.425 6.86042 10.5 6.36667 10.5 5.825L10 5.475L9.3 5.7L8.575 7.875L8.95 8.55L9.525 8.6Z" fill={color}/>
        </svg>
    </svg>;
}