import { PlotParams } from "react-plotly.js";
import {
  BaseContext,
  PlayerEvaluationContext,
  Report,
  ReportContent,
  ReportRequest,
  ReportResponse
} from "../models";
import { config } from "../config";

export interface MessageContent {
  content: string,
}

export interface MessagePayload extends MessageContent {
  role: string,
  args?: string
};

export interface MessageModel extends MessagePayload {
  id: number,
  chatId: number,
  plots?: any,
  redirect?: any,
  disliked?: boolean,
};

export interface ChatContextData {
  version: string,
  gender?: string,
  country?: string,
  competition_id?: number,
  year: number,
  position?: string,
  team?: string,
  player_name?: string,
  quality?: string,
}

export interface MatchEvaluationContext {
  version: string,
  competition: {
    id: number,
    year: number,
  },
  match: {
    id: number,
    team: {
      id: number,
    }
  }
  quality: "OUTCOME" | "ATTACK" | "DEFENCE" | "ATTACKING_TRANSITION" | "DEFENSIVE_TRANSITION" | "CHANCE_CREATION";
}

export type ChatPayload = {
  id: number,
  analyst_name: string,
  context: PlayerEvaluationContext;
}

export type ChatModel = {
  id: number,
  name: string,
  analyst_name: string,
  type: string,
  context: ChatContextData,
  messages: MessageModel[] | null
};

export type ConversationModel = {
  id: number,
  created_at: Date,
  name: string,
}

export type ChartResponseType = 'plotly' | 'json';

// This is used for streaming messages. Each chunk may be a partial message or a full message. There is no messageID at this point (because it is generated). Therefore the index property is used. Multiple items with the same index should be collapsed into one message with all content_chunks joined in the order of arrival. Most properties are the same as the MessageModel

export type StreamedResponseChunk = {
  index: number,
  role: "system" | "tool" | "assistant",
  tool_calls?: any,
  content_chunk?: string,
  redirect?: any,
  plots?: any[],
  tool_call_names?: Array<string>,
};

export interface PitchChartZone {
  name: string;
  color_value: number;
  hover_text: string;
  annotation?: string;
  bounding_box: {
    x_min: number;
    x_max: number;
    y_min: number;
    y_max: number;
  };
};

export interface ChartModel {
  title: string;
  subtitle: string;
  target_type: "player" | "match";
  plot_type: "radar" | "distribution" | "pitch";
  kpi_type: "metric";
  data: {
    focal_target_indices: Array<number>;
    hover_strings: Array<Array<string>>;
    kpi_names: Array<string>;
    kpis: Array<{
      name: string;
      description: string;
      display_name: string;
    }>;
    plot_values: Array<Array<number>>;
    target_labels: Array<string>;
    value_range: [number, number];
    minutes_played: number;
    zones?: Array<PitchChartZone>;
  };
  player_info?: {
    name: string;
    full_name: string;
    position: string;
  }
}

export interface PlayerModel {
  name: string;
  jersey_number: number;
}

export interface MatchTeamModel {
  name: string;
  lineup: {
    starting: Array<PlayerModel>;
    substitutes: Array<PlayerModel>;
    formation: Array<Array<number>>;
  }
}

export interface MatchInfoModel {
  date: Date| string;
  homeTeam: MatchTeamModel;
  awayTeam: MatchTeamModel;
  stadium?: string;
  summary: {
    metrics: Array<{
      name: string;
      htValue: number;
      atValue: number;
    }>
  }
}

// If the streamed messages belong to multiple chats (as a result of a redirect), then there will be multiple lists of messages returned. 

type StreamedResponseCallback = (chunks: StreamedResponseChunk[][]) => void;

type TwelveApi = {
  setChatInitialized: (chatId: number) => boolean;
  deleteInitializedChat: (chatId: number) => void;
  listConversations: (token: string, search?: string, createdBefore?: Date) => Promise<ConversationModel[]>;
  getConversation: (token: string, conversationId: number) => Promise<ConversationModel>;
  createConversation: (token: string, name: string) => Promise<ConversationModel>;
  updateConversationName: (token: string, conversationId: number, newName: string) => Promise<ConversationModel>;
  renameConversation: (token: string, conversationId: number) => Promise<ConversationModel>;
  deleteConversation: (token: string, conversationId: number) => Promise<void>;
  listChats: (token: string, conversationId: number) => Promise<ChatModel[]>;
  createChat: (token: string, conversationId: number, name: string, analystName: string, context: BaseContext | PlayerEvaluationContext | MatchEvaluationContext) => Promise<ChatModel>;
  showChat: (token: string, conversationId: number, chatId: number) => Promise<ChatModel>;
  
  /**
   * Adds a new message to a chat. It takes a callback that receives the parsed chunks from the 
   * stream as they arrive. Note that the callback receives the full list of parsed messages
   * each call, so it is not only the items that were parsed since last callback. This makes
   * the rendering code slightly cleaner since we do not need to build the state there. 
   * 
   * When the streaming is completed, all the chat messages are refetched (sinceId can be used
   * to limit the number of messages fetched). 
   * 
   * NOTE: In the callback, if the streamed messages belong to multiple chats (as a result of 
   * a redirect), then there will be multiple lists of messages returned. 
   * 
   * @param conversationId 
   * @param chatId 
   * @param content 
   * @param callback 
   * @param sinceId 
   * @returns 
   */
  
  addChatMessage: (token: string, conversationId: number, chatId: number, content: MessageContent, callback: StreamedResponseCallback, sinceId: number | undefined) => Promise<MessageModel[]>;
  updateMessageDisliked: (token: string, conversationId: number, chatId: number, messageId: number, disliked: boolean) => Promise<MessageModel>;
  getChart: (path: string, json_body: Record<string, any>, responseType: ChartResponseType, useCache?: boolean) => Promise<ChartModel | PlotParams>;
  getReport: (token: string, payload: ReportRequest) => Promise<ReportContent>;
  getReportByPublicId: (publicId: string) => Promise<ReportResponse>;
  getSavedReports: (token: string, page?: number, limit?: number, search?: string) => Promise<Array<Report>>;
}

function createFetchOptions(token: string, data: any = undefined, method: string = 'POST') {
  let options: any = {
    method,
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      "Content-Type": "application/json",
      ...getAuthHeader(token),
    },
  }
  if (data) {
    options.body = JSON.stringify(data);
  }
  return options;
}

function getAuthHeader(token: string) {
  return { Authorization: `Bearer ${token}` };
}

/**
 * Processes a text buffer (JSON-L format), extracts lines and parses each line 
 * into a StreamedResponseChunk. 
 * 
 * @param text 
 * @returns A tuple with a list of parsed chunks and the remaining unparsed text (if any)
 */

function extractChunks(text: string): [StreamedResponseChunk[], string] {
  let reminder = "";
  let sep = '\n';
  let l = 0;
  const splits = [];
  while (l >= 0) {
    let r = text.indexOf(sep, l);
    if (r >= 0) {
      splits.push(text.substring(l, r));
    } else {
      reminder = text.substring(l);
      break;
    }
    l = r + sep.length;
  }
  try {
    const chunks: StreamedResponseChunk[] = splits.map((chunk) => JSON.parse(chunk));
    return [chunks, reminder];
  } catch (e) {
    console.error(e);
  }
  return [[], text];
}

/**
 * Collapse multiple chunks of the same index into one chunk. Messages are collapsed 
 * by adding their content_chunk properties together
 * 
 * Also figure out if we have messages that belong to a new chat. This is done by 
 * checking if the previous message has the redirect property. If this is the case, then 
 * it is assumed that the message belong to a newly created chat. 
 * 
 * NOTE: This method will probably not work if we do parallel function calls. In that
 * case it could be refactored to inspect messages with role = 'tool' that has a redirect
 * property. That type of message will indicate a new chat has been started and all 
 * the following messages belong to that chat. It is in this case important to make
 * sure that all parallel messages from the previous chat are sent before the redirect
 * message. Otherwise this will break (it could be fixed with a temporary chat id but
 * then things start to get a bit complicated)
 * 
 * @param chunks 
 * @returns If the streamed messages belong to multiple chats (as a result of a redirect), then there will be multiple lists of messages returned.
 */

function collapseChunks(chunks: StreamedResponseChunk[]): StreamedResponseChunk[][] {
  if (chunks.length === 1) {
    return [chunks];
  }
  let buffer = [chunks[0]];
  const result = [];
  chunks.slice(1).forEach((c) => {
    const previous = buffer[buffer.length - 1];
    if (previous.redirect) {
      result.push(buffer);
      buffer = [c];
     } else if (c.index === previous.index) {
      const v = {...previous, ...c};
      if (previous.content_chunk) {
        v.content_chunk = previous.content_chunk + v.content_chunk ?? '';
      }
      buffer[buffer.length - 1] = v;
     } else {
      buffer.push(c);
     }
  });
  result.push(buffer);
  return result;
}

export function createTwelveApi(endpoint: string): TwelveApi {
  const _initializedChats = new Set<number>();

  const setChatInitialized = (chatId: number) => {
    if (_initializedChats.has(chatId)) {
      return false;
    }
    _initializedChats.add(chatId);
    return true;
  }

  const deleteInitializedChat = (chatId: number) => {
    _initializedChats.delete(chatId);
  }

  const getMessages = async (token: string, conversationId: number, chatId: number, sinceId?: number) => {
    let url = `${endpoint}/conversations/${conversationId}/chats/${chatId}/messages`;
    if (sinceId) {
      url = `${url}&since_id=${sinceId}`;
    }
    const response = await fetch(url, { headers: getAuthHeader(token) });
    return await response.json();
  };

  const listConversations = async (token: string, search?: string, createdBefore?: Date): Promise<ConversationModel[]> => {
    let query_params = []
    if (createdBefore) {
      query_params.push(`created_before=${createdBefore}`);
    }
    if (search) {
      query_params.push(`search=${search}`);
    }
    const url = `${endpoint}/conversations?${query_params.join('&')}`;
    const response = await fetch(url, { headers: getAuthHeader(token) });
    return await response.json();
  }

  const getConversation = async (token: string, conversationId: number): Promise<ConversationModel> => {
    const url = `${endpoint}/conversations/${conversationId}`;
    const response = await fetch(url, { headers: getAuthHeader(token) });
    return await response.json();
  }

  const createConversation = async (token: string, name: string):Promise<ConversationModel> => {
    const url = `${endpoint}/conversations`;
    const options = createFetchOptions(token, { name });
    const response = await fetch(url, options);
    return await response.json();
  };

  const updateConversationName = async (token: string, conversationId: number, newName: string):Promise<ConversationModel> => {
    const url = `${endpoint}/conversations/${conversationId}/name`;
    const options = createFetchOptions(token, { new_name: newName }, 'PUT');
    const response = await fetch(url, options);
    return response.json();
  };

  const renameConversation = async (token: string, conversationId: number):Promise<ConversationModel> => {
    const url = `${endpoint}/conversations/${conversationId}/rename`;
    const options = createFetchOptions(token, null);
    const response = await fetch(url, options);
    return await response.json();
  };

  const deleteConversation = async (token: string, conversationId: number):Promise<void> => {
    const url = `${endpoint}/conversations/${conversationId}`;
    const options = createFetchOptions(token, null, 'DELETE');
    await fetch(url, options);
  };

  const listChats = async (token: string, conversationId: number): Promise<ChatModel[]> => {
    const url = `${endpoint}/conversations/${conversationId}/chats`;
    const response = await fetch(url, { headers: getAuthHeader(token) });
    return await response.json();
  }

  const createChat = async (token: string, conversationId: number, name: string, analystName: string, context: BaseContext | PlayerEvaluationContext | MatchEvaluationContext):Promise<ChatModel> => {
    const url = `${endpoint}/conversations/${conversationId}/chats`;
    const options = createFetchOptions(token, { name, analyst_name: analystName, context });
    const response = await fetch(url, options);
    return await response.json();
  };

  const showChat = async (token: string, conversationId: number, chatId: number): Promise<ChatModel> => {
    const url = `${endpoint}/conversations/${conversationId}/chats/${chatId}`;
    const response = await fetch(url, { headers: getAuthHeader(token) });
    return await response.json();
  }

  const addChatMessage = async (token: string, conversationId: number, chatId: number, content: MessageContent, callback: StreamedResponseCallback, sinceId: number | undefined = undefined): Promise<MessageModel[]> => {
    const url = `${endpoint}/conversations/${conversationId}/chats/${chatId}/messages`;
    const options = createFetchOptions(token, content);
    const response = await fetch(url, options);
    const reader = response.body?.getReader();
    let buffer = "";
    const chunks: StreamedResponseChunk[] = [];
    const utf8Decoder = new TextDecoder("utf-8");
    return new Promise((resolve, reject) => {
      reader?.read()
        .then(function processText({ done, value }): any {
          if (done) {
            return;
          }
          buffer += utf8Decoder.decode(value);
          const [extractedChunks, reminder] = extractChunks(buffer);
          buffer = reminder;
          chunks.push(...extractedChunks);
          callback(collapseChunks(chunks));
          return reader.read().then(processText);
        })
        .then(() => getMessages(token, conversationId, chatId, sinceId).then(resolve))
        .catch(reject);
    });
  };

  const updateMessageDisliked = async (token: string, conversationId: number, chatId: number, messageId: number, disliked: boolean): Promise<MessageModel> => {
    const url = `${endpoint}/conversations/${conversationId}/chats/${chatId}/messages/${messageId}/dislike`;
    const options = createFetchOptions(token, { disliked: disliked }, "PUT");
    const response = await fetch(url, options);
    return await response.json();
  }

  // NOTE: 
  // Here follows a hack to avoid spamming of the backend API when the graph is rendered. 
  // This is because the graph component performs its own url request and since we rerender 
  // the pending messages frequently this will trigger a lot of calls. One could consider 
  // to handle this in the chat component instead, i.e. by using useMemo and treating graphs 
  // differently from text messages. But all these methods would make the code more complex 
  // than this solution. Another solution would be to take a bigger grip on the caching and 
  // allow it for all calls so it feels more consistent. But that also adds complexity so I 
  // do not think it is time for that just yet. 

  const chartCache: Record<string, any> = {};

  /**
   * Ensures a record is converted to a cacheable string consistently
   * 
   * @param record 
   * @returns A cacheable string
   */

  function cacheKeyFromRecord(record: Record<string, any>) {
    const keys = Object.keys(record);
    keys.sort();
    return JSON.stringify(keys.map((k) => ([k, record[k]])));
  }

  const getChart = async (path: string, json_body: Record<string, any>, responseType: ChartResponseType, useCache: boolean = true): Promise<ChartModel | PlotParams> => {
    const url = `${endpoint}/chart${path}`;
    const cacheKey = `${url}-${cacheKeyFromRecord(json_body)}`;
    if (useCache && cacheKey in chartCache) {
      return chartCache[cacheKey];
    }
    const options = createFetchOptions(config.guest_token, json_body);
    const response = await fetch(url, options);
    const result = await response.json();
    if (useCache) {
      chartCache[cacheKey] = result;
    }
    switch (responseType) {
      case 'plotly':
        return result as PlotParams;
      default:
        return result as ChartModel;
    }
  }

  const getReport = async (token: string, payload: ReportRequest): Promise<ReportContent> => {
    const options = createFetchOptions(token, payload);
    const response = await fetch(`${endpoint}/report`, options);
    return await response.json();
  }

  const getReportByPublicId = async (publicId: string): Promise<ReportResponse> => {
    const options = createFetchOptions(config.guest_token, undefined, 'GET');
    const response = await fetch(`${endpoint}/report/${publicId}`, options);
    return await response.json();
  }

  const getSavedReports = async (token: string, page: number = 1, limit: number = 30, search?: string): Promise<Array<Report>> => {
    const url = `${endpoint}/report/saved?page=${page}&limit=${limit}${search ? `&search=${search}` : ''}`;
    const response = await fetch(url, { headers: getAuthHeader(token) });
    return await response.json();
  }

  const api: TwelveApi = {
    setChatInitialized,
    deleteInitializedChat,
    createConversation,
    listConversations,
    getConversation,
    updateConversationName,
    renameConversation,
    deleteConversation,
    listChats,
    createChat,
    showChat,
    addChatMessage,
    getChart,
    getReport,
    getReportByPublicId,
    getSavedReports,
    updateMessageDisliked,
  }
  return api;
}

function lookupApiEndpoint() {
  const hostname = window.location.hostname.toLowerCase();
  const protocol = window.location.protocol;

  switch (hostname) {
    case 'localhost':
    case '127.0.0.1':
      return `${protocol}//127.0.0.1:7000`;
    case 'twelvegptv2ui.z6.web.core.windows.net':
    case 'gpt-v2-dev.twelve.football':
      return `${protocol}//gpt-v2-api-dev.twelve.football`;
    default:
      throw new Error("Production api endpoint not set yet");
      // return `${protocol}//api.twelve.football`;
  }
}

export const api = Object.freeze(createTwelveApi(lookupApiEndpoint()));