import {
    Box,
    TypographyProps,
    Card,
    CardContent,
    CardContentProps,
    IconButton,
    Typography,
    styled,
    BoxProps,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { ReactComponent as Logo } from "../elements/logo-circle.svg";
import { CreateConversationButton } from "./CreateConversationButton";

const CardContainer = styled(Box)<BoxProps>(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    gap: '16px',
    alignItems: 'center',
    margin: 0,
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
        maxWidth: '332px',
    },
}));

const StyledCardContent = styled(CardContent)<CardContentProps>(({ theme }) => ({
    minWidth: '150px',
    maxWidth: '150px',
    height: '100px',
    padding: '6px 6px 0 6px',
    [theme.breakpoints.down('sm')]: {
    },
}));

const StyledTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    padding: '12px',
    [theme.breakpoints.down('sm')]: {
    },
}));

export default function ExampleConversations() {
    const examples = [
        "A midfielder that would fit our team",
        "Performance of our last match",
        "Improvements from last season",
        "Match report for our opponents",
    ];
  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '12px'}}>
            <Logo  style={{marginBottom: '12px'}}/>
            <Typography variant="caption" sx={{fontSize: '18px', fontWeight: 700, color: (theme) => theme.palette.grey[500]}}>Welcome to TwelveGPT!</Typography>
            {false ? <CardContainer>
                {examples.map((example, index) => (
                    <Card key={`ex-${index}`} variant="outlined">
                        <StyledCardContent>
                            <Box>
                                <IconButton color="inherit" size="small">
                                    <EditIcon color="action" sx={{fontSize: "16px"}} />
                                </IconButton>
                            </Box>
                            <StyledTypography variant="body2">{example}</StyledTypography>
                        </StyledCardContent>
                    </Card>
                ))}
            </CardContainer>
            : <CreateConversationButton />
            }
        </Box>
    </Box>
  );
}
