import React from "react";
import { PlayerSummaryReportSlide, ReportPage } from "../../../models";
import {
    Page,
    PageBox,
    PageBoxContent,
    PageBoxHeader,
    PageContent,
    PageHeader,
    PageHeaderProfile
} from "../ReportLayout";
import {
    Box,
    Grid,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { PlayerPositionChart } from "../../charts/PlayerPositionChart";
import PerformanceBar from "../PerformanceBar";
import StringHelper from "../../../helpers/string.helper";

export default function PlayerSummaryPage({page, pageNumber}: {page: ReportPage, pageNumber: number}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const playerInfo = page.slides![0] as PlayerSummaryReportSlide;

    const normalizeKey = StringHelper.normalizeKey;

    return <Page key={`page-${pageNumber}`} sx={{height: 'unset'}}>
        {!isMobile && <PageHeader title={page.header} subtitle={page.subheader} />}
        <PageContent>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                width: '100%',
                [theme.breakpoints.down("md")]: {
                    flexDirection: 'column',
                }
            }}>
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                }}>
                    <PageHeaderProfile title={playerInfo?.player_name || ''} src={playerInfo.player_image_url} />
                    <Box sx={{
                        display: 'flex',
                        gap: '16px',
                        height: '100%',
                        [theme.breakpoints.down("md")]: {
                            flexDirection: 'column',
                        }
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                            width: '100%',
                            maxWidth: '320px',
                            [theme.breakpoints.down("md")]: {
                                maxWidth: '100%',
                            }
                        }}>
                            <PageBox sx={{flexGrow: 1}}>
                                <PageBoxHeader title='Info'/>
                                <PageBoxContent>
                                    <Grid container spacing={1}>
                                        {Object.entries(playerInfo?.player_info_card).map(([key, value]) =>
                                            <React.Fragment key={`info-${key}`}>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body2">
                                                        {normalizeKey(key)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Typography align={isMobile ? 'left' : 'right'} variant="body2" sx={{color: theme.palette.customColors.text.secondary}}>
                                                        {value ? normalizeKey(String(value)) : '-'}
                                                    </Typography>
                                                </Grid>
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                </PageBoxContent>
                            </PageBox>
                            <PageBox sx={{flexGrow: 1}}>
                                <PageBoxHeader title='League Season'/>
                                <PageBoxContent>
                                <Grid container spacing={1}>
                                        {Object.entries(playerInfo?.player_season_info_card).map(([key, value]) =>
                                            <React.Fragment key={`info-${key}`}>
                                                <Grid item xs={12} md={8}>
                                                    <Typography variant="body2">
                                                        {normalizeKey(key)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Typography align={isMobile ? 'left' : 'right'} variant="body2" sx={{color: theme.palette.customColors.text.secondary}}>
                                                        {String(value)}
                                                    </Typography>
                                                </Grid>
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                </PageBoxContent>
                            </PageBox>
                        </Box>
                        <PageBox sx={{height: '100%', width: '100%'}}>
                            <PageBoxHeader title='Header'/>
                            <PageBoxContent>
                                <Typography
                                    sx={{
                                        whiteSpace: 'pre-line',
                                    }}
                                    variant="body1"
                                    dangerouslySetInnerHTML={{
                                        __html: playerInfo?.player_summary_card.description,
                                    }}
                                />
                            </PageBoxContent>
                        </PageBox>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    minWidth: '20%',
                    alignContent: 'stretch',
                    [theme.breakpoints.down("md")]: {
                    }
                }}>
                    <PageBox sx={{flexGrow: 1}}>
                        <PageBoxContent>
                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                    <Typography component={'p'} variant="body1" sx={{fontSize: '18px', fontWeight: 700}}>
                                        Main position
                                    </Typography>
                                    <Typography component={'p'} variant="body2">
                                        {normalizeKey(playerInfo.position_card.main_position)}
                                    </Typography>
                                    <br/>
                                    <Typography component={'p'} variant="body1" sx={{color: theme.palette.customColors.text.secondary}}>
                                        Other positions
                                    </Typography>
                                    <Typography component={'p'} variant="body2" sx={{color: theme.palette.customColors.text.secondary}}>
                                        {normalizeKey(playerInfo.position_card.other_position)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} sx={{display: 'flex', justifyContent: 'end', paddingTop: 0}}>
                                    <PlayerPositionChart width={'200px'} positions={[playerInfo.position_card.main_position.toUpperCase()]} style={{height: '100%'}} />
                                </Grid>
                            </Grid>
                        </PageBoxContent>
                    </PageBox>
                    <PageBox sx={{flexGrow: 1}}>
                        <PageBoxHeader title='Strenghts'/>
                        <PageBoxContent>
                            <Grid container spacing={1} alignItems={'center'}>
                                {playerInfo?.strengths_card.items.sort((a,b) => a.rank - b.rank).map((qualityItem, index) => (
                                    <React.Fragment key={`strenghts-${index}`}>
                                        <Grid item xs={4}>
                                            <Typography component={'p'} variant="body2">
                                                {qualityItem.name}
                                            </Typography>
                                            <Typography component={'p'} variant="body2" fontSize={12} sx={{opacity: 0.6}}>
                                                {qualityItem.rank}/{qualityItem.rank_out_of}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <PerformanceBar
                                                value={100 - 100*(qualityItem.rank - 1)/(qualityItem.rank_out_of - 1)}
                                                hoverText={`
                                                    ${playerInfo.player_name}<br/>
                                                    Ranked ${qualityItem.rank}/${qualityItem.rank_out_of}<br/>
                                                    Compared to other ${normalizeKey(playerInfo.position_card.main_position)}s
                                                `}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ))}
                            </Grid>
                        </PageBoxContent>
                    </PageBox>
                    <PageBox sx={{flexGrow: 1}}>
                        <PageBoxHeader title='Weaknesses'/>
                        <PageBoxContent>
                            <Grid container spacing={1} alignItems={'center'}>
                                {playerInfo?.weaknesses_card.items.sort((a,b) => a.rank - b.rank).map((qualityItem, index) => (
                                    <React.Fragment key={`strenghts-${index}`}>
                                        <Grid item xs={4}>
                                            <Typography component={'p'} variant="body2">
                                                {qualityItem.name}
                                            </Typography>
                                            <Typography component={'p'} variant="body2" fontSize={12} sx={{opacity: 0.6}}>
                                                {qualityItem.rank}/{qualityItem.rank_out_of}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <PerformanceBar
                                            value={100 - 100*(qualityItem.rank - 1)/(qualityItem.rank_out_of - 1)}
                                            hoverText={`
                                                ${playerInfo.player_name}<br/>
                                                Ranked ${qualityItem.rank}/${qualityItem.rank_out_of}<br/>
                                                Compared to other ${normalizeKey(playerInfo.position_card.main_position)}s
                                            `}
                                        />
                                        </Grid>
                                    </React.Fragment>
                                ))}
                            </Grid>
                        </PageBoxContent>
                    </PageBox>
                </Box>
            </Box>
        </PageContent>
    </Page>
}