import {
    Container,
    ContainerProps,
    Box,
    BoxProps,
    styled,
} from "@mui/material";
import AppLogo from "../components/elements/AppLogo";
import SignIn from "../components/welcome/SignIn";
import ChatExamples from "../components/welcome/ChatExamples";

const LogoContainer = styled(Box)<BoxProps>(({ theme }) => ({
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "200px",
    padding: "0px",
    [theme.breakpoints.up("md")]: {
        left: "auto",
        right: "-10px",
    }
}));
  
const MainContainer = styled(Container)<ContainerProps>(({ theme }) => ({
    paddingLeft: "0px!important",
    paddingRight: "0px!important",
    display: "flex",
    [theme.breakpoints.up("md")]: {
        maxWidth: "100vw",
        backgroundColor: [theme.palette.primary.dark],
        color: [theme.palette.primary.light],
    },
}));

export default function Welcome() {
    return (
        <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            overflow: 'hidden',
        }}>
            <LogoContainer>
                <AppLogo width="120px" height="40px" padding="30px" />
            </LogoContainer>
            <MainContainer>
                <SignIn />
                <ChatExamples />
            </MainContainer>
        </Box>
    );
}
