import { Box } from '@mui/material';
import PlayerScoutingReport from '../components/reports/ScoutingReport';
import { useEffect, useState } from 'react';
import { api } from '../api/twelve';
import { useAuth0 } from '@auth0/auth0-react';
import PageLoader from '../components/elements/PageLoader';
import { useLocation } from 'react-router-dom';
import {
  ReportContent,
  ReportRequest
} from '../models';

export default function ReportPage() {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState<boolean>(true);
  const [reportContent, setReportContent] = useState<ReportContent>();
  const location = useLocation();
  const report = location.state?.report as ReportRequest;

  useEffect(() => {
    if (!report) return;

    const getReport = async () => {
      setLoading(true);
      try {
        const content = await api.getReport(await getAccessTokenSilently(), report);
        setReportContent(content);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getReport();
  }, [getAccessTokenSilently, report]);

  const renderReport = () => {
    switch (report.report_type) {
      case "SCOUT_REPORT":
        return <PlayerScoutingReport {...reportContent} />;
      default:
        return <></>;
    }
  };

  return <Box>
    {!loading
      ? renderReport()
      : <PageLoader variant='full' />
    }
  </Box>
}