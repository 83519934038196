import { PaletteOptions, SimplePaletteColorOptions, createTheme } from "@mui/material";

export interface CustomColors {
  background: {
    main: string;
    secondary: string;
    tertiary: string;
  };
  primary: {
    main: string;
    light: string;
  };
  secondary: {
    main: string;
  };
  accent: string;
  title: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
  text: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
  dataPoints: Array<string>;
};

declare module '@mui/material/styles' {
  interface Palette {
    disabled: Palette['primary'];
    customColors: CustomColors;
  }

  interface PaletteOptions {
    disabled?: PaletteOptions['primary'];
    customColors?: CustomColors;
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    disabled: true;
  }
}

export const DefaultCustomColors : CustomColors = {
  background: {
    main: "#041B00",
    secondary: "rgba(0, 0, 0, 0.04)",
    tertiary: "rgba(255, 255, 255, 0.08)",
  },
  primary: {
    main: "#002C1C",
    light: "#FFFFFF",
  },
  secondary: {
    main: "#009940",
  },
  accent: "#FF4B00",
  title: {
    primary: "#FFFFFF",
    secondary: "rgba(255,255,255,0.6)",
    tertiary: "#014633",
  },
  text: {
    primary: "#FFFFFF",
    secondary: "rgba(255,255,255,0.6)",
    tertiary: "#014633",
  },
  dataPoints: [
    "#009940",
    "#FFFFFF",
    "#FFCC00",
    "#0095FF",
    "#FF4B00",
    "#8000FF",
    "#FF0000",
    "#0019F6",
    "#77001D",
    "#FF00A8",
  ],
};

const defaultPalette: PaletteOptions = {
  primary: {
    main: "#014633",
    dark: "#002c1c",
    light: "#ff5722",
    contrastText: "#fff",
  },
  secondary: {
    main: "#FA4A02",
    contrastText: "#fff",
  },
  error: {
    main: "#ff0000",
  },
  warning: {
    main: "#ffa726",
  },
  info: { 
    main: "#5A657E" 
  },
  success: { 
    main: "#74A98E" 
  },
  text: {
    primary: "#000",
  },
  disabled: {
    main: 'rgba(0, 0, 0, 0.26)',
  },
  customColors: DefaultCustomColors,
};



export const theme = createTheme({
  palette: defaultPalette,
  typography: {
    fontFamily: "Gilroy",
  },
  components: {
    // Name of the component
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
          borderRadius: '3px',
          textTransform: 'unset',
          //border: `1px solid ${(defaultPalette.secondary! as SimplePaletteColorOptions).main}`,
          //color: (defaultPalette.secondary! as SimplePaletteColorOptions).main
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          background: (defaultPalette.primary! as SimplePaletteColorOptions).main,
        }
      }
    },
    MuiCircularProgress: {
      variants: [
        {
          props: { 
            size: 'small' 
          },
          style: {
            width: '30px',
            height: '30px'
          },
        }
      ]
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          color: '#666',
          fontWeight: 700,
        },
      },
    },
  },
});
