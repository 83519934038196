import { Tooltip, useTooltip } from './Tooltip';
import { BaseChartProps, PitchChartWrapper } from './ChartLayout';
import { ChartBody, ChartContainer, ChartFooter, ChartHeader } from './ChartLayout';
import { Box } from '@mui/material';
import { DefaultCustomColors } from '../theme';
import { PitchChartZone } from '../../api/twelve';

interface FullPitchChartProps extends BaseChartProps {
    title: string;
    subTitle?: string;
    labels?: Array<string>;
    footerText?: string;
    data: any;
};

export function FullPitchChart(props: FullPitchChartProps) {
  const { tooltip, showTooltip, hideTooltip } = useTooltip();
  const { colors = DefaultCustomColors, size } = props;

  const x = (value: number) => value*1.05;
  const y = (value: number) => value*0.68;

  return <>
    <ChartContainer {...props}>
      <ChartHeader {...props} />
      <ChartBody colors={colors} size={size}>
        <PitchChartWrapper {...props} backgroundLikeGrid={false}>
          <g>{
            props.data.map((item: PitchChartZone, index: number) => {
              const zx = x(item.bounding_box.x_min);
              const zy = y(100 - item.bounding_box.y_max);
              const width = x(item.bounding_box.x_max - item.bounding_box.x_min);
              const height = y(item.bounding_box.y_max - item.bounding_box.y_min);
              return <g
                key={`zone-${index}`}
                onMouseMove={(event: any) => {
                  const { pageX: x, pageY: y } = event;
                  showTooltip(item.hover_text?.replaceAll('\n', '<br/>'), {x, y});
                }}
                onMouseOut={() => hideTooltip()}
              >
                <rect
                  x={zx}
                  y={zy}
                  width={width}
                  height={height}
                  fill={`rgba(0, 153, 64, ${item.color_value})`}
                >
                </rect>
                <text
                  x={zx + width/2}
                  y={zy + height/2}
                  fill={colors.text.secondary}
                  alignmentBaseline='middle'
                  textAnchor='middle'
                  fontSize={'3px'}
                  fontWeight={200}
                  transform={item.name === "own penalty area" ? `rotate(90,${zx + width/2},${zy + height/2})` : 'rotate(0)'}
                >
                  {item.annotation}
                </text>
              </g>;
            })
          }</g>
        </PitchChartWrapper>
      </ChartBody>
      {props.footerText
        ? <ChartFooter colors={colors} size={size}>
            <Box sx={{color: colors.text.secondary}}>
              {props.footerText}
            </Box>
          </ChartFooter>
        : <></>
      }
    </ChartContainer>
    <Tooltip tooltip={tooltip} />
  </>;
}