import {
    Container,
    ContainerProps,
    Box,
    BoxProps,
    Typography,
    TypographyProps,
    Button,
    ButtonProps,
    styled,
    useTheme,
  } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

const SignInContainer = styled(Container)<ContainerProps>(({ theme }) => ({
    backgroundColor: [theme.palette.primary.dark],
    color: [theme.palette.primary.main],
    width: "100vw",
    height: "100vh",
    padding: "0px",
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up("md")]: {
        maxWidth: "482px",
        backgroundColor: [theme.palette.background.default],
        color: [theme.palette.primary.contrastText],
    },
}));

const ButtonsContainer = styled(Box)<BoxProps>(({ theme }) => ({
    width: "100%",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: '10px',
    [theme.breakpoints.up("md")]: {
        width: "100%",
        maxWidth: "420px",
        flexDirection: "row",
        justifyContent: "space-between",
    },
}));

const StyledHeading = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: [theme.palette.primary.light],
    fontSize: '34px',
    textAlign: 'center',
    [theme.breakpoints.up("md")]: {
        color: [theme.palette.text.secondary],
    },
}));

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
    width: "200px",
    borderWidth: '1px',
    borderStyle: 'solid',
}));

const SignIn = () => {
    const { loginWithRedirect } = useAuth0();
    const theme = useTheme();
    return (
        <SignInContainer>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '40px',
                }}
            >
                <StyledHeading variant="h1">Sign in</StyledHeading>
                <ButtonsContainer>
                    <StyledButton
                        variant="contained"
                        sx={{
                            color: (theme) => theme.palette.primary.contrastText,
                            backgroundColor: (theme) => theme.palette.primary.main,
                            borderColor: (theme) => theme.palette.primary.main,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.primary.light,
                                borderColor: (theme) => theme.palette.primary.light,
                                color: (theme) => theme.palette.primary.contrastText,
                            },
                            [theme.breakpoints.down("md")]: {
                                backgroundColor: (theme) => theme.palette.background.paper,
                                color: (theme) => theme.palette.primary.dark,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.main,
                                    color: (theme) => theme.palette.primary.contrastText,
                                    borderColor: (theme) => theme.palette.background.paper
                                },
                            },
                        }}
                    >
                        Register for trial
                    </StyledButton>
                    <StyledButton
                        variant="contained"
                        sx={{
                            borderColor: (theme) => theme.palette.background.paper,
                            backgroundColor: (theme) => theme.palette.background.paper,
                            color: (theme) => theme.palette.primary.dark,
                            "&:hover": {
                                color: (theme) => theme.palette.primary.contrastText,
                                backgroundColor: (theme) => theme.palette.primary.main,
                                borderColor: (theme) => theme.palette.primary.main,
                            },
                            [theme.breakpoints.down("md")]: {
                                color: (theme) => theme.palette.primary.contrastText,
                                backgroundColor: (theme) => theme.palette.primary.dark,
                                "&:hover": {
                                    backgroundColor: (theme) => theme.palette.primary.main,
                                    borderColor: (theme) => theme.palette.background.paper,
                                },
                            },
                        }}
                        onClick={() => loginWithRedirect()}
                    >
                        Log in
                    </StyledButton>
                </ButtonsContainer>
            </Box>
        </SignInContainer>
    )
}

export default SignIn