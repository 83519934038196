import React, { ReactElement } from "react";
import { ReactComponent as SvgLogo } from "./logo.svg";

import { Box, css, styled } from "@mui/material";

interface LogoProps {
  height?: string;
}

interface AppLogoProps {
  children?: ReactElement;
  padding?: string;
  height?: string;
  width?: string;
}

const Logo = styled(SvgLogo, {
  shouldForwardProp: (prop) => prop !== 'height',
})<LogoProps>(({ theme, height, width }) => ({
  '&': css`
    width: 100%;
    max-width: 100%;
    height: ${height};
    width: ${width};
  `
}));

export const LOGO_HEIGHT = '122px';
export const LOGO_WIDTH = '263px';
export const LOGO_PADDING = '29px';

export default function AppLogo({children, padding, width, height}: AppLogoProps) {
  return <Box sx={{p: '1rem', px: padding || LOGO_PADDING }}>
    <Box>
      <Logo height={height || LOGO_HEIGHT} width={width || LOGO_WIDTH} />
    </Box>
    <Box>
      {children}
    </Box>
  </Box>
}