import { LinearProgress } from '@mui/material';
import { CustomColors, DefaultCustomColors } from '../theme';
import { Tooltip, useTooltip } from '../charts/Tooltip';

export default function PerformanceBar({
  value,
  colors = DefaultCustomColors,
  height = '12px',
  color1 = colors.text.primary,
  color2 = colors.text.secondary,
  hoverText = '',
} : {
  value: number;
  colors?: CustomColors;
  height?: number | string;
  color1?: string;
  color2?: string;
  hoverText?: string;
}) {
  const { tooltip, showTooltip, hideTooltip } = useTooltip();

  return <>
    <LinearProgress
      variant="determinate"
      value={value}
      sx={{
        height,
        borderRadius: '6px',
        backgroundColor: color2,
        '& .MuiLinearProgress-bar': {
          background: color1
        }
      }}
      onMouseMove={(event: any) => {
        if (!hoverText) return
        const { pageX: x, pageY: y } = event;
        showTooltip(hoverText, {x, y});
      }}
      onMouseOut={() => hideTooltip()}
    />
    <Tooltip tooltip={tooltip} />
  </>;
};
