import * as React from 'react';
import { styled, keyframes } from '@mui/system';

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  mt: 4,
  mb: 4,
});

const dotsAnimation = keyframes`
  20%{background-position:0%   0%, 50%  50%,100%  50%}
  40%{background-position:0% 100%, 50%   0%,100%  50%}
  60%{background-position:0%  50%, 50% 100%,100%   0%}
  80%{background-position:0%  50%, 50%  50%,100% 100%}
`;

const Animation = styled('div')({
  width: '30px',
  aspectRatio: 2,
  background: `
    no-repeat radial-gradient(circle closest-side,#888 90%,#0000) 0%   50%,
    no-repeat radial-gradient(circle closest-side,#888 90%,#0000) 50%  50%,
    no-repeat radial-gradient(circle closest-side,#888 90%,#0000) 100% 50%;
  `,
  backgroundSize: `calc(100%/3) 50%`,
  animation: `${dotsAnimation} 1s infinite linear`,
});

export default function TextLoader() {
  return <Container><Animation /></Container>;
}