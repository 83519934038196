import React, { useState, useRef, useEffect } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { useLoaderData, useLocation, useRevalidator } from "react-router-dom";
import { Box, Fab } from "@mui/material";
import Chat from "../components/chat/Chat";
import { ChatModel, ConversationModel } from "../api/twelve";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { config } from "../config";
import { renameConversation } from "../store/conversationsReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";


export default function Conversation() {
  const location = useLocation();
  const { conversation, chats } = useLoaderData() as { conversation: ConversationModel; chats: ChatModel[] };
  const conversationId = parseInt(location.pathname.split("/").pop() ?? "0");
  const revalidator = useRevalidator();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const selectedConversationName = useSelector((state: RootState) => state.conversations.selectedConversation?.name);

  useEffect(() => {
    onScroll();
  }, [conversationId]);

  useEffect(() => {
    const renameConversationIfPossible = async () => {
      if (selectedConversationName === config.conversationNaming.default) {
        if (chats.filter(c => c.analyst_name === "SCOUT").length > 0) {
          dispatch(renameConversation(await getAccessTokenSilently(), conversation.id));
        }
      }
    }
    renameConversationIfPossible();
  }, [selectedConversationName, conversation, chats, getAccessTokenSilently, dispatch]);

  const [showScrollButton, setShowScrollButton] = useState(false);
  const scrollRef = useRef<any>(null);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      setShowScrollButton(scrollTop < scrollHeight - clientHeight - 1);
    }
  };

  const onRedirect = (chatId: number) => {
    revalidator.revalidate();
  };

  return (
    <Box sx={{
      height: '100%',
      position: 'relative',
    }}>
      <Box sx={{
          width: '100%',
          height: 'calc(100% - 92px)',
          overflowX: 'clip',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        ref={scrollRef}
        onScroll={onScroll}
      >
        {chats.map((c: ChatModel, i: number) => <Chat key={c.id} conversationId={conversation.id} chat={c} hasInput={i >= (chats.length - 1)} onRedirect={onRedirect}/>)}
      </Box>

      {showScrollButton && (
        <Fab
          size="small"
          sx={{
            position: 'absolute',
            bottom: '100px',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
          onClick={scrollToBottom}
        >
          <ArrowDownwardIcon />
        </Fab>
      )}
    </Box>
  );
}
