import { Box } from '@mui/material';
import PlayerScoutingReport from '../components/reports/ScoutingReport';
import { useEffect, useState } from 'react';
import { api } from '../api/twelve';
import PageLoader from '../components/elements/PageLoader';
import { useParams } from 'react-router-dom';
import {
  ReportResponse,
} from '../models';

export default function PublicReportPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [report, setReport] = useState<ReportResponse>();
  const { publicId } = useParams();

  useEffect(() => {
    if (!publicId ) {
      return;
    }
    const getReport = async () => {
      setLoading(true);
      try {
        const report = await api.getReportByPublicId(publicId);
        setReport(report);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getReport();
  }, [publicId])

  const renderReport = () => {
    switch (report?.report_type) {
      case "SCOUT_REPORT":
        return <PlayerScoutingReport {...report.content} />;
      default:
        return <></>;
    }
  };

  return <Box>
    {!loading
      ? renderReport()
      : <PageLoader variant='full' />
    }
  </Box>
}