import {
    Box,
    Container,
    ContainerProps,
    Typography,
    TypographyProps,
    styled,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { ReactElement } from "react";
import { CustomColors, DefaultCustomColors } from '../theme';

export interface BaseChartProps extends ContainerProps {
    colors?: CustomColors;
    size?: 'lg' | 'sm';
};

interface ChartContainerProps extends BaseChartProps {
    children?: ReactElement | Array<ReactElement>;
    width?: number | string;
    height?: number | string;
    sx?: any;
};

interface ChartHeaderProps extends BaseChartProps {
    title: string;
    subTitle?: string;
};

interface ChartFooterProps extends ChartContainerProps {
    height?: number;
    children: ReactElement | Array<ReactElement>;
};

interface StyledTypographyProps extends TypographyProps {
    colors?: CustomColors;
    size?: 'lg' | 'sm';
} 

const StyledContainer = styled(Container, {
    shouldForwardProp: (prop) => prop !== 'colors'
})<ChartContainerProps>(({ theme, colors = DefaultCustomColors, size = 'lg' }) => {
    const smStyle = {
        backgroundColor: 'transparent',
        border: 'unset',
        maxWidth: '360px!important',
        maxHeight: '436px',
        padding: '0!important',
    };

    return {
        fontFamily: theme.typography.fontFamily,
        backgroundColor: colors.primary.main,
        border: `1px solid ${colors.primary.main}`,
        borderRadius: '8px',
        padding: '24px 32px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '8px',
        ...(size === 'sm' ? smStyle : {}),
        [theme.breakpoints.down('sm')]: size !== 'sm' && {...smStyle, maxHeight: 'unset!important'},
        [theme.breakpoints.down('md')]: {
            maxWidth: '360px',
            maxHeight: '436px'
        }
    }
});

const StyledHeader = styled("div", {
    shouldForwardProp: (prop) => prop !== 'colors',
})<ChartContainerProps>(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
    }
}));

const StyledBody = styled("div", {
    shouldForwardProp: (prop) => prop !== 'colors'
})<ChartContainerProps>(({ theme, colors = DefaultCustomColors, size = 'lg' }) => {
    const smStyle: any = {
        borderRadius: '8px',
        backgroundColor: colors.primary.main,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '320px',
        justifyContent: 'center',
    };

    return {
        display: 'contents',
        ...(size === 'sm' ? smStyle : {}),
        [theme.breakpoints.down('sm')]: size !== 'sm' && smStyle,
    };
});

export const StyledBodyText = styled("div", {
    shouldForwardProp: (prop) => prop !== 'colors'
})<ChartContainerProps>(({ theme, colors = DefaultCustomColors, size = 'lg' }) => {
    const smStyle: any = {
        fontSize: '12px',
        minHeight: '32px',
    };

    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.customColors.text.secondary,
        fontWeight: 200,
        ...(size === 'sm' ? { ...smStyle, alignItems: 'flex-start'} : {}),
        [theme.breakpoints.down('md')]: {
            fontSize: '12px',
        },
        [theme.breakpoints.down('sm')]: size !== 'sm' && smStyle,
    };
});

const StyledFooter = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'colors',
})<ChartContainerProps>(({ theme, colors = DefaultCustomColors, size = 'lg' }) => {
    const smStyle = {
        borderTop: 'unset',
        borderRadius: '8px',
        backgroundColor: colors.primary.main,
        paddingTop: '0',
    };

    return {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.text.primary,
        fontWeight: 200,
        borderTop: `1px solid ${colors.dataPoints[0]}80`,
        paddingTop: '20px',
        ...(size === 'sm' ? smStyle : {}),
        [theme.breakpoints.down('sm')]: size !== 'sm' && smStyle,
    };
});

const StyledTitle = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'colors',
})<StyledTypographyProps>(({ theme, colors = DefaultCustomColors, size = 'lg' }) => {
    const smStyle = {
        fontSize: '16px',
    };

    return {
        fontSize: '18px',
        color: colors.title.primary,
        fontWeight: 700,
        lineHeight: 'normal',
        letterSpacing: '0.15px',
        [theme.breakpoints.down('md')]: {
            fontSize: '16px',
        },
        ...(size === 'sm' ? smStyle : {}),
        [theme.breakpoints.down('sm')]: size !== 'sm' && smStyle,
    };
});

const StyledSubTitle = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'colors',
})<StyledTypographyProps>(({ theme, colors = DefaultCustomColors, size = 'lg' }) => {
    const smStyle = {
        fontSize: '12px',
    };

    return {
        fontSize: '16px',
        color: colors.title.secondary,
        fontWeight: 600,
        ...(size === 'sm' ? smStyle : {}),
        [theme.breakpoints.down('sm')]: size !== 'sm' && smStyle,
    };
});

export function ChartHeader({ colors, title, subTitle, size }: ChartHeaderProps) {
    return (
        <StyledHeader colors={colors} size={size}>
            <StyledTitle variant='caption' colors={colors} size={size}>{title}</StyledTitle>
            <StyledSubTitle variant='caption' colors={colors} size={size}>{subTitle}</StyledSubTitle>
        </StyledHeader>
    );
}

export function ChartFooter({ children, colors, size, height = 64 }: ChartFooterProps) {
    return (
        <StyledFooter sx={{height: `${height}px`}} colors={colors} size={size}>
            {children}
        </StyledFooter>
    );
}

export function ChartBody({children, colors, size, sx = {}}: ChartContainerProps) {
    return (
        <StyledBody colors={colors} size={size} sx={{...sx}}>
            {children}
        </StyledBody>
    );
}

export function ChartContainer({children, colors, size, width = '560px', height = '600px', sx = {} }: ChartContainerProps) {
    return (
        <StyledContainer sx={{ width, height, ...sx}} colors={colors} size={size}>
            {children}
        </StyledContainer>
    );
}

type PitchChartWrapperProps = {
    labels?: Array<string>;
    footerText?: string;
    children?: ReactElement;
    orientation?: 'horizontal' | 'vertical';
    childrenOverPitch?: boolean;
    backgroundLikeGrid?: boolean;
};

export function PitchChartWrapper({
    labels,
    children,
    orientation,
    childrenOverPitch,
    backgroundLikeGrid = true,
}: PitchChartWrapperProps) {
    const theme = useTheme();
    const colors = theme.palette.customColors;
    const strokeWidth = 0.3;
    const strokeColor = '#FFFFFF';
    const isVertical = orientation === 'vertical';
    const width: number = 490;
    const height: number = 365;
    const padding = {
      top: 0,
      bottom: 24,
      left: 0,
      right: 0,
    };
    const plotPadding = 10;
    const footerHeight: number = 0;
    const x = (value: number) => 1.05*value;
    const y = (value: number) => 0.68*(100 - value);

    const w = isVertical ? height/1.85 : width;
    const h = isVertical ? width/2.5 : height;

    const style: React.CSSProperties = {};
    if (isVertical) {
        style.height = '100%';
    } else {
        style.width = '100%';
    }

    return <svg
        viewBox={`0 0 ${w} ${h}`}
        style={{
            ...style
        }}
    >
        <g transform={isVertical ? `rotate(90 ${w/2} ${h/2})`: ``}>
            <svg viewBox={`0 0 ${width} ${height}`} fontFamily='Poppins'>
                <g>
                    <svg viewBox={`0 0 ${105 + 2*3} ${68 + 2*1}`}>
                    <g transform={`translate(3,1)`}>
                        {backgroundLikeGrid && Array.from(Array(10).keys()).map((_, index) => {
                            const w = 105/10;
                            return<rect key={`field-${index}`} width={w+0.1} height={68} fill={index%2 ? '#91D087' : '#82C577'} x={w*index} />;
                        })}
                        {childrenOverPitch !== true && children}
                        {/* Pitch bounds */}
                        <path d='M 0 0 H 105 V 68 H 0 Z' fill='none' stroke={strokeColor} strokeWidth={strokeWidth} />
                        {/* Halfway line */}
                        <line x1={x(50)} y1={y(0)} x2={x(50)} y2={y(100)} fill='none' stroke={strokeColor} strokeWidth={strokeWidth} />
                        {/* Center circle */}
                        <circle cx={x(50)} cy={y(50)} r={9.15} stroke={strokeColor} strokeWidth={strokeWidth} fill='none' />
                        {/* Center spot */}
                        <circle cx={x(50)} cy={y(50)} r={strokeWidth} stroke={strokeColor} strokeWidth={strokeWidth} fill='none' />
                        {/* Left penalty area */}
                        <rect x={x(0)} y={y(81)} width={x(16)} height={y(19) - y(81)} stroke={strokeColor} strokeWidth={strokeWidth} fill='none' />
                        {/* Left goal area */}
                        <rect x={x(0)} y={y(62)} width={x(6)} height={y(38) - y(62)} stroke={strokeColor} strokeWidth={strokeWidth} fill='none' />
                        <rect x={-2} y={34 - 7.32/2} width={2} height={7.32} fill={strokeColor} fillOpacity={.5} />
                        {/* Left penalty spot */}
                        <circle cx={x(11)} cy={y(50)} r={strokeWidth} stroke={strokeColor} strokeWidth={strokeWidth} fill={strokeColor} />
                        {/* Right penalty area */}
                        <rect x={x(84)} y={y(81)} width={x(16)} height={y(19) - y(81)} stroke={strokeColor} strokeWidth={strokeWidth} fill='none' />
                        {/* Right goal area */}
                        <rect x={x(94)} y={y(62)} width={x(6)} height={y(38) - y(62)} stroke={strokeColor} strokeWidth={strokeWidth} fill='none' />
                        <rect x={105} y={34 - 7.32/2} width={2} height={7.32} fill={strokeColor} fillOpacity={.5} />
                        {/* Right penalty spot */}
                        <circle cx={x(89)} cy={y(50)} r={strokeWidth} stroke={strokeColor} strokeWidth={strokeWidth} fill={strokeColor} />
                        {/* Left penalty arc */}
                        <path d="M5.499,-7.313A9.15,9.15,0,0,1,5.499,7.313A9.15,9.15,0,0,0,5.499,-7.313Z" transform="translate(11.235,34) rotate(0)" stroke={strokeColor} strokeWidth={strokeWidth}></path>
                        {/* Right penalty arc */}
                        <path d="M5.499,-7.313A9.15,9.15,0,0,1,5.499,7.313A9.15,9.15,0,0,0,5.499,-7.313Z" transform="translate(93.765,34) rotate(180)" stroke={strokeColor} strokeWidth={strokeWidth}></path>
                        {/* Corner arcs */}
                        <path d="M0,-1A1,1,0,0,1,1,0A1,1,0,0,0,0,-1Z" transform="translate(0,68) rotate(0)" stroke={strokeColor} strokeWidth={strokeWidth}></path>
                        <path d="M0,-1A1,1,0,0,1,1,0A1,1,0,0,0,0,-1Z" transform="translate(0,0) rotate(90)" stroke={strokeColor} strokeWidth={strokeWidth}></path>
                        <path d="M0,-1A1,1,0,0,1,1,0A1,1,0,0,0,0,-1Z" transform="translate(105,0) rotate(180)" stroke={strokeColor} strokeWidth={strokeWidth}></path>
                        <path d="M0,-1A1,1,0,0,1,1,0A1,1,0,0,0,0,-1Z" transform="translate(105,68) rotate(279)" stroke={strokeColor} strokeWidth={strokeWidth}></path>
                        {childrenOverPitch === true && children}
                    </g>
                    </svg>
                </g>
                {
                    labels
                    &&
                    <g>
                    {labels.map((label, index) => {
                        const labelsCount = labels?.length || 1;
                        return <text
                            key={`label-${index}`}
                            x={plotPadding + (index + 0.5)*(width - 2*plotPadding)/labelsCount}
                            y={height - padding.bottom - footerHeight + 10}
                            fill={colors.text.secondary}
                            textAnchor='middle'
                            fontSize={'14px'}
                            fontWeight={200}
                            opacity={0.5}
                        >
                            {label}
                        </text>
                    })}
                    </g>
                }
            </svg>
        </g>
    </svg>
}